<template>
  <div id="tags">
    <UIBox classbox="hello">
      <template v-slot:title>
        <span class="bold">Broadcast</span>
      </template>
      <div class="filter" v-if="role === 'Admins'">
        <div class="filterBody">
          <div class="button-container">
            <button :class="['btn', 'solid', { 'btn-menu-active': showTrackings }]"  @click="handleTrackings">Trackings</button>
            <button :class="['btn', 'solid', { 'btn-menu-active': showNegativeKeyordFilter }]"  @click="handleNegativeKeywordFilter">Negative Keyword Filter</button>
            <button :class="['btn', 'solid', { 'btn-menu-active': showManageDIDs }]"  @click="handleManageDIDs">Manage DIDs</button>
            <button :class="['btn', 'solid', { 'btn-menu-active': showManagePremaidMessages }]"  @click="handleManagePremadeMessages">Manage Premade Messages</button>
          </div>
        </div>
      </div>
      <template>
        <Trackings v-if="showTrackings" />
          <NegativeKeywordFilter v-if="showNegativeKeyordFilter" />
          <ManageDIDs v-if="showManageDIDs" />
          <PreMadeMessages v-if="showManagePremaidMessages" />
          <div class="app">
            <Stepper 
              :steps="steps" 
              :initial-step="0"
              @step-change="handleStepChange"
            >
            </Stepper>
          </div>
      </template>
    </UIBox>
  </div>
</template>

<script>
import AuthService from '../_services/AuthService.js';

import UIBox from '../components/uibox.vue';
import Stepper from './broadcast/Stepper.vue';
import Trackings from './broadcast/Trackings.vue';
import NegativeKeywordFilter from './broadcast/NegativeKeywordFilter.vue';
import ManageDIDs from './broadcast/ManageDIDs.vue';
import PreMadeMessages from './broadcast/PreMadeMessages.vue';

export default {
  components: {
    UIBox,
    Stepper,
    Trackings,
    NegativeKeywordFilter,
    ManageDIDs,
    PreMadeMessages,
  },
  data() {
    return {
      showTrackings: false,
      showNegativeKeyordFilter: false,
      showManageDIDs: false,
      showManagePremaidMessages: false,
      steps: [
        { 
          title: 'Select Lead', 
          icon: '',
          description: 'Define the main objectives and goals for the design project.'
        },
        { 
          title: 'Select Industry', 
          icon: '',
          description: 'Gather and analyze user data to inform design decisions.'
        },
        { 
          title: 'Select How Many Leads To Send', 
          icon: '',
          description: 'Develop initial layouts and structural designs.'
        },
        { 
          title: 'Choose Premade Message', 
          icon: '',
          description: 'Create the visual elements and styling of the design.'
        },
        { 
          title: 'Send Broadcast', 
          icon: '',
          description: '.'
        }
      ],
      role: '',
      userId: ''
    }
  },
  methods: {
    handleTrackings() {
      this.showTrackings = !this.showTrackings;
      this.showNegativeKeyordFilter = false;
      this.showManageDIDs = false;
      this.showManagePremaidMessages = false;
    },
    handleNegativeKeywordFilter() {
      this.showNegativeKeyordFilter = !this.showNegativeKeyordFilter;
      this.showTrackings = false;
      this.showManageDIDs = false;
      this.showManagePremaidMessages = false;
    },
    handleManageDIDs() {
      this.showManageDIDs = !this.showManageDIDs;
      this.showManagePremaidMessages = false;
      this.showNegativeKeyordFilter = false;
      this.showTrackings = false;
    },
    handleManagePremadeMessages() {
      this.showManagePremaidMessages = !this.showManagePremaidMessages;
      this.showManageDIDs = false;
      this.showNegativeKeyordFilter = false;
      this.showTrackings = false;
    },
    handleStepChange(stepIndex) {
      console.log('Current step:', stepIndex);
      // Add any additional logic for step changes
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
      console.log('this.role', this.role);
      console.log('this.userId', this.userId);
    },
  },
  async mounted() {
    await this.initUser();
  }
}
</script>
<style scoped>
.button-container {
  display: flex;
  justify-content: space-between;
  /* or use space-evenly for equal space between and on edges */
  width: 100%;
  gap: 10px;
  /* optional: adds consistent spacing between buttons */
}

.app {
  padding: 20px;
  width: 100% !important;
}

.custom-content {
  margin-top: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
}

.btn-menu-active {
  background-color: white !important; /* Example active color - adjust as needed */
  color: #000 !important;
}
</style>