<template>
  <div id="users">
    
    <UIBox classbox="">
      
      <template v-slot:title>
        <span class="bold">Teams</span>
      </template>
      
      <template>
        <div class="filter">
          <div class="filterHead">Filter by</div>
          <div class="filterBody">
            <div class="form-group select">
              <select v-model="filterManager" class="form-control">
                <option value="">All Managers</option>
                <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ manager.name }}</option>
              </select>
            </div>
            <div class="form-group select" style="margin-left:1%;">
              <select v-model="userType" class="form-control">
                <option value="All">All Users</option>
                <option v-for="userType in userTypes" :value="userType">{{ userType }}</option>
              </select>
            </div>
            <div class="form-group select" style="margin-left:1%;">
              <select v-model="filterTeamSize" class="form-control">
                <option value="All">All Teams</option>
                <option value="lessThan10">Teams < 10</option>
                <option value="greaterThan10">Teams > 10</option>
              </select>
            </div>
          </div>
          <div class="filterToggles">
            <button @click="openNewUser" class="btn solid icon">Add New User<PlusSVG /></button>
          </div>
        </div>
        
        <div v-for="manager in filteredManagers" :key="manager.id" class="table-fullwidth">
          <div class="tablehead">
            <h3 :style="{ backgroundColor: roleColor() }">
              <div id="manager-dashboard">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  @click.prevent="openRepDashboard(manager)"
                  class="dashboard-link"
                >
                  {{ manager.name }}
                </a>
              </div>
              <span><strong> ({{ manager.agents.length }} agents)</strong></span>
              <button @click="editProfile(manager)" class="btn solid">Edit Profile</button>
            </h3>
            <button v-if="manager.agents.length === 0" @click="removeManager(manager.id)" class="btn btn-link text-danger">Remove</button>
          </div>
          <table class="table">
            <thead class="">
              <tr>
                <th>Login</th>
                <th>Role</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email/Username</th>
                <th>Phone</th>
                <th v-if="userRole==='Admins' || userRole === 'Recruiters'">Manager</th>
                <th>Hire Date</th>
                <th>Shelf Life</th>
                <th>Age</th>
                <th>Timezone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="rep in manager.agents" :key="rep.id">
                <td><button class="btn solid" @click="openRepDashboard(rep)" >Login</button></td>
                <td>{{ rep.role === 'Roaming_Closer' ? 'Sales Rep' :  rep.role }}</td>
                <td><input v-model="rep.firstName" type="text" class="form-control"></td>
                <td><input v-model="rep.lastName" type="text" class="form-control"></td>
                <td><input v-model="rep.email" type="email" class="form-control" readonly></td>
                <td><input v-model="rep.phone" type="tel" class="form-control" @input="formatPhone(rep)"></td>
                <td v-if="userRole==='Admins' || userRole === 'Recruiters'">
                  <select v-model="rep.managerId" @change="handleManagerChange(rep.id, rep.managerId)" class="form-control">
                    <option value="">None</option>
                    <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ manager.name }}</option>
                  </select>
                </td>
                <td>{{ formatDate(rep.createdAt) }}</td>
                <td>{{ calculateShelfLife(rep.createdAt) }} days</td>
                <td>{{ calculateAge(rep.dob) }}</td>
                <td>{{ rep.timezone }}</td>
                <td>
                  <button @click="removeUser(rep.id)" class="btn solid" v-if="userRole==='Admins' || userRole === 'Managers'">Remove</button>
                  <!-- <button @click="openResetPasswordModal(rep)" class="btn btn-link text-primary">Reset Password</button> -->
                  <button @click="editProfile(rep)" class="btn solid">Edit Profile</button>
                  <button @click="resendWelcomeEmail(rep)" class="btn solid">Send Welcome Email</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </template>
    </UIBox>

    <!-- Add New User Modal -->
    <b-modal v-model="showModal" title="Add New User" body-class="threecolumn">
      <form @submit.prevent="createUser">
        <div class="col-a">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input v-model="newUser.firstName" type="text" id="firstName" class="form-control">
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input v-model="newUser.lastName" type="text" id="lastName" class="form-control">
          </div>
          <div class="form-group">
            <label for="email">Email/Username</label>
            <input v-model="newUser.email" type="email" id="email" class="form-control">
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input v-model="formattedPhone" type="tel" id="phone" class="form-control" @input="formatPhoneNumber">
          </div>
        </div>
        <div class="col-b">
          <div class="form-group" v-if="((newUser.role !== 'Admins' && userRole !== 'Recruiters' && userRole !== 'Recruiter_Managers') || newUser.role === 'Recruiters') && newUser.role != 'Operators'">
            <label for="manager">Manager</label>
            <select v-model="newUser.managerId" id="manager" class="form-control">
              <option value="">None</option>
              <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ manager.name }}</option>
            </select>
          </div>
          <div class="form-group" v-if="newUser.role === 'Operators' && (userRole === 'Recruiter_Managers' || userRole === 'Admins')">
            <label for="recruiter">Recruiter</label>
            <select v-model="newUser.recruiterId" id="recruiter" class="form-control">
              <option value="">None</option>
              <option v-for="recruiter in recruiters" :key="recruiter.id" :value="recruiter.id">{{ recruiter.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="dob">Date of Birth</label>
            <input v-model="newUser.dob" type="date" id="dob" class="form-control">
          </div>
          <div class="form-group">
            <label for="role">Role</label>
            <select v-model="newUser.role" id="role" class="form-control">
              <option value="Operators">Operator</option>
              <option value="Managers" v-if="userRole==='Admins' || userRole==='Recruiters' || userRole==='Recruiter_Managers'">Manager</option>
              <option value="Recruiters" v-if="userRole==='Admins' || userRole==='Recruiter_Managers'">Recruiter</option>
              <option value="Recruiter_Managers" v-if="userRole==='Admins'">Recruiter Manager</option>
              <option value="Roaming_Closer" v-if="userRole==='Admins' || userRole==='Recruiters' || userRole==='Recruiter_Managers'">Sales Rep</option>
            </select>
          </div>
        </div>
        <div class="col-c" style="margin-right:5%;">
          <div class="form-group" v-if="newUser.role === 'Managers'">
            <label for="firstName">1st Day Meeting Link</label>
            <input v-model="newUser.meetLink" type="text" id="meetLink" class="form-control">
          </div>
          <div class="form-group" v-if="newUser.role == 'Operators'">
            <label for="startDate">Start Date</label>
            <input v-model="newUser.startDate" type="date" id="startDate" class="form-control">
          </div>
          <div class="form-group">
            <label for="whatsapp">WhatsApp Phone #</label>
            <input v-model="formattedWhatsapp" type="tel" id="whatsapp" class="form-control" @input="formatWhatsapp">
          </div>
        </div>
      </form>
      <template #modal-footer>
        <b-button @click="showModal = false" variant="secondary" class="ml-2">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="createUser">Create User</b-button>
      </template>
    </b-modal>

    <!-- Reset Password Modal -->
    <b-modal v-model="showResetPasswordModal" title="Reset Password">
      <form @submit.prevent="resetPassword">
        <div class="form-group">
          <label for="newPassword">New Password</label>
          <input v-model="passwordForm.newPassword" type="password" id="newPassword" class="form-control">
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password</label>
          <input v-model="passwordForm.confirmPassword" type="password" id="confirmPassword" class="form-control">
        </div>
        <div v-if="passwordError" class="alert alert-danger">
          {{ passwordError }}
        </div>
        <b-button @click="showResetPasswordModal = false" variant="secondary" class="ml-2">Cancel</b-button>
        <b-button type="submit" variant="primary">Save</b-button>
      </form>
    </b-modal>

    <!-- Edit Profile Modal -->
    <b-modal v-model="showSetDOBModal" title="Edit Profile">
      <form>
        <div class="col-a">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input v-model="newUser.firstName" type="text" id="firstName" class="form-control">
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input v-model="newUser.lastName" type="text" id="lastName" class="form-control">
          </div>
          <div class="form-group">
            <label for="email">Email/Username</label>
            <input v-model="newUser.email" type="email" id="email" class="form-control">
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input v-model="formattedPhone" type="tel" id="phone" class="form-control" @input="formatPhoneNumber">
          </div>
        </div>
        <div class="col-b">
          <div class="form-group" v-if="newUser.role !== 'Admins'">
            <label for="manager">Manager</label>
            <select v-model="newUser.managerId" id="manager" class="form-control">
              <option value="">None</option>
              <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ manager.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="dob">Date of Birth</label>
            <input v-model="newUser.dob" type="date" id="dob" class="form-control">
          </div>
          <div class="form-group">
            <label for="role">Role</label>
            <select v-model="newUser.role" id="role" class="form-control">
              <option value="Operators">Operator</option>
              <option value="Managers" v-if="userRole==='Admins' || userRole==='Recruiters'">Manager</option>
              <option value="Recruiters" v-if="userRole==='Admins'">Recruiter</option>
              <option value="Recruiter_Managers" v-if="userRole==='Admins'">Recruiter Manager</option>
            </select>
          </div>
        </div>
        <div class="col-c">
          <div class="form-group" v-if="newUser.role !== 'Operators'">
            <label for="meetLink">1st Day Meeting Link</label>
            <input v-model="newUser.meetLink" id="meetLink" type="text" class="form-control">
          </div>
          <div class="form-group">
            <label for="startDate">Start Date</label>
            <input v-model="newUser.startDate" type="date" id="startDate" class="form-control">
          </div>
          <div class="form-group">
            <label for="whatsapp">WhatsApp Phone #</label>
            <input v-model="formattedWhatsapp" type="tel" id="whatsapp" class="form-control" @input="formatWhatsapp">
          </div>
        </div>
        <div class="col-c">
          <div class="form-group">
            <label for="timezone">Timezone</label>
            <select v-model="newUser.timezone" id="timezone" class="form-control">
              <option v-for="timezone in timezones" :key="timezone" :value="timezone">{{ timezone }}</option>
            </select>
          </div>
        </div>
      </form>
      <template #modal-footer>
        <b-button @click="showSetDOBModal = false" variant="secondary" class="ml-2">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="updateUser">Update User</b-button>
      </template>
    </b-modal>

        <!-- Notification -->
    <b-modal v-model="showNotification" title="New Operator Orientation">
      <div class="content" style="background-color: #f7f7f7; padding: 20px; border-radius: 10px; margin-top: 20px;">
          <h2 style="color: #2d572c; font-size: 20px; font-weight: bold; margin: 0;">Get Ready for Your First Day of Work!</h2>
          <p style="font-size: 16px; line-height: 1.5;">
              Your first day starts at <strong>8:00 AM Pacific Standard Time</strong>. You have already arranged your start date with your hiring recruiter, so everything is ready for your big day!
          </p>
          <p style="font-size: 16px; line-height: 1.5;">
              You'll be meeting <strong>{{manager.firstName}}, your new manager</strong>, who will lead a quick 1-hour training session with you at 8:00 AM sharp.
          </p>
          <p style="font-size: 16px; line-height: 1.5;">
              To reach {{manager.firstName}} directly for any questions or assistance, please contact him at:
          </p>
          <p style="font-size: 16px; line-height: 1.5; font-weight: bold;">
              Phone: {{manager.phone}}<br>
              Email: {{manager.email}}<br>
              Meet Link: {{manager.meetLink}}
          </p>
          <p style="font-size: 16px; line-height: 1.5;">
              Simply click the button below to join your training session and meet your new manager!
          </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { BModal } from 'bootstrap-vue';
import AuthService from '../../_services/AuthService.js';
import UIBox from '../../components/uibox.vue';
import PlusSVG from '../../components/SVG-icons/plusSVG.vue';

export default {
  components: {
    BModal, UIBox, PlusSVG
  },
  data() {
    return {
      showModal: false,
      showResetPasswordModal: false,
      showSetDOBModal: false,
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: 'Operators',
        managerId: '',
        dob: '',
        timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
      },
      passwordForm: {
        newPassword: '',
        confirmPassword: ''
      },
      passwordError: '',
      dobForm: {
        dob: ''
      },
      selectedRep: null,
      searchQuery: '',
      filterManager: '',
      salesReps: [],
      managers: [],
      inactiveAgents: [],
      userRole: '',
      userId: '',
      showNotification: false,
      error: false,
      manager: {
        firstName: '',
        phone: '',
        email: '',
        meetLink: ''
      },
      recruiters: [],
      userTypes: ['Operators', 'Sales Reps', 'Managers', 'Recruiters', 'Recruiter Managers', 'Admins'],
      userType: 'All',
      formattedPhone: '',
      formattedWhatsapp: '',
      filterTeamSize: 'All', 
    };
  },
  watch: {
    formattedPhone(value) {
      // Set `newUser.phone` in E.164 format whenever `formattedPhone` changes
      this.newUser.phone = this.convertToE164(value);
    },
    formattedWhatsapp(value) {
      this.newUser.whatsapp = this.convertToE164(value);
    }
  },
  computed: {
    filteredManagers() {
      let selectedUserType = this.userType !== 'All' ? this.userType : null;

      if (selectedUserType === 'Sales Reps') {
        selectedUserType = 'Roaming_Closer';
      }

      let managers = this.managers.map(manager => ({
        ...manager,
        agents: this.salesReps.filter(rep =>
          rep.managerId === manager.id &&
          this.filterActiveReps(rep) &&
          (!selectedUserType || rep.role === selectedUserType)
        )
      }));

      // Apply team size filter
      if (this.filterTeamSize === 'lessThan10') {
        managers = managers.filter(manager => manager.agents.length < 10);
      } else if (this.filterTeamSize === 'greaterThan10') {
        managers = managers.filter(manager => manager.agents.length > 10);
      }

      // Sort managers by the number of agents
      managers = managers.sort((a, b) => a.agents.length - b.agents.length);

      if (this.filterManager) {
        const filteredManager = managers.find(manager => manager.id === parseInt(this.filterManager));
        return filteredManager ? [filteredManager] : [];
      }

      return managers;
    },
  },
  methods: {
    roleColor() {
      const colors = {
        admins: '#E7B6B6',
        managers: '#B6C3E7',
        operators: '#B6E7C3',
        roaming_closer: 'B3E1E8',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    formatPhoneForDisplay(phone) {
      // Remove non-numeric characters
      const cleaned = phone.replace(/\D/g, '');
      let formatted = '';

      if (cleaned.length > 10) {
        const countryCode = cleaned.slice(0, cleaned.length - 10);
        const areaCode = cleaned.slice(cleaned.length - 10, cleaned.length - 7);
        const firstPart = cleaned.slice(cleaned.length - 7, cleaned.length - 4);
        const secondPart = cleaned.slice(cleaned.length - 4);
        formatted = `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
      } else {
        const areaCode = cleaned.slice(0, 3);
        const firstPart = cleaned.slice(3, 6);
        const secondPart = cleaned.slice(6);
        formatted = `(${areaCode}) ${firstPart}-${secondPart}`;
      }

      return formatted;
    },
    formatPhoneNumber() {
      // Format the `formattedPhone` for display
      const cleaned = this.formattedPhone.replace(/\D/g, '');
      let formatted = '';

      if (cleaned.length > 10) {
        const countryCode = cleaned.slice(0, cleaned.length - 10);
        const areaCode = cleaned.slice(cleaned.length - 10, cleaned.length - 7);
        const firstPart = cleaned.slice(cleaned.length - 7, cleaned.length - 4);
        const secondPart = cleaned.slice(cleaned.length - 4);
        formatted = `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
      } else {
        const areaCode = cleaned.slice(0, 3);
        const firstPart = cleaned.slice(3, 6);
        const secondPart = cleaned.slice(6);
        formatted = `(${areaCode}) ${firstPart}-${secondPart}`;
      }

      this.formattedPhone = formatted;
    },
    formatWhatsapp() {
      // Format the `formattedPhone` for display
      const cleaned = this.formattedWhatsapp.replace(/\D/g, '');
      let formatted = '';

      if (cleaned.length > 10) {
        const countryCode = cleaned.slice(0, cleaned.length - 10);
        const areaCode = cleaned.slice(cleaned.length - 10, cleaned.length - 7);
        const firstPart = cleaned.slice(cleaned.length - 7, cleaned.length - 4);
        const secondPart = cleaned.slice(cleaned.length - 4);
        formatted = `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
      } else {
        const areaCode = cleaned.slice(0, 3);
        const firstPart = cleaned.slice(3, 6);
        const secondPart = cleaned.slice(6);
        formatted = `(${areaCode}) ${firstPart}-${secondPart}`;
      }

      this.formattedWhatsapp = formatted;
    },
    convertToE164(phone) {
      // Convert to E.164 format by stripping non-numeric characters and adding `+` if needed
      const cleaned = phone.replace(/\D/g, '');
      return `+${cleaned}`;
    },
    openNewUser() {
      this.newUser = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: 'Operators',
        managerId: '',
        recruiterId: '',
        dob: '',
        startDate: '',
        whatsapp: '',
        timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
      };
      this.showModal = true;
    },
    async resendWelcomeEmail(rep) {
      try {
        let response = await axios.post('https://123avatars.com/v2/resend-welcome-email', {
          id: rep.id,
          managerId: rep.managerId
        });
        alert('Email successfully resent.');
      } catch (err) {
        console.error('error resending email', err);
        alert('Error sending welcome email.');
      }
    },
    openRepDashboard(user) {
      // let host = '';
      // if(this.role == 'Admins') {
      //   host = 'https://beta.123avatars.com';
      // }
      // if(this.role == 'Managers') {
      //   host = 'https://myleadfarm.co';
      // }
      // window.open(host + '/my-sales?id=' + salesRepId, '_blank');
      console.log('user: ', user);
      AuthService.setOverrideState(user.sub, user.role);
    },
    async handleManagerChange(repId, managerId) {
      console.log(`Manager ID changed to: ${managerId}`);
      try {
        const response = await axios.post('https://123avatars.com/v2/change-manager', {
          repId: repId,
          managerId: managerId
        });

        console.log('Response:', response.data);
      } catch (error) {
        console.error('Error changing manager:', error);
      }
    },
    async fetchRecruiters() {
      try {
      const params = {
        role: this.role,
        userId: this.userId
      };
      let response = await axios.get('https://123avatars.com/v2/users?filter=Recruiters');

      this.recruiters = response.data
        .map(rep => ({
            id: rep.sub,
            name: rep.firstName + ' ' + rep.lastName
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
      console.error('Error fetching sales reps:', error);
      }
    },
    async fetchSalesReps() {
      try {
        let target = 'https://123avatars.com/v2/sales-reps';
        let role = await AuthService.getUserRole();
        console.log('role: ', role);
        console.log('path: ', this.$route.path);
        if(role == 'Admins' && this.$route.path == '/users') {
          //target += '?id=' + this.$route.query.id;
        }
        
        if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
          target += '?managerId=' + this.$route.query.id;
        }
        if(role == 'Managers') {
          target += '?managerId=' + await AuthService.getCognitoSub();
        }
        const response = await axios.get(target);
        this.salesReps = response.data.filter(rep => (rep.role === 'Operators' || rep.role === 'Recruiters' || rep.role === 'Roaming_Closer'));
        console.log('salesReps: ', JSON.stringify(this.salesReps));
        this.managers = response.data.filter(rep => (rep.role === 'Managers' || rep.role === 'Recruiter_Managers'));
      } catch (error) {
        console.error('Error fetching sales reps:', error);
      }
    },
    async createUser() {
      try {
        // Validate required fields
        if (!this.newUser.firstName) {
          alert('First name is required.');
          return;
        }

        if (!this.newUser.lastName) {
          alert('Last name is required.');
          return;
        }

        if (!this.newUser.email) {
          alert('Email is required.');
          return;
        }

        // Email format validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(this.newUser.email)) {
          alert('Invalid email format.');
          return;
        }

        // Optional phone validation (must be in E.164 format if provided)
        //if (this.newUser.phone) {
          const phonePattern = /^\+[1-9]\d{1,14}$/; // E.164 format (supports optional '+')
          if (!phonePattern.test(this.newUser.phone)) {
            alert('Phone number must be in E.164 format (e.g., +1234567890).');
            return;
          }
      //  }

      if(this.newUser.role === 'Managers') {
        const urlPattern = /^https:\/\/meet\.google\.com\/[a-z]{3}-[a-z]{4}-[a-z]{3}$/;
        if(this.newUser.meetLink && !urlPattern.test(this.newUser.meetLink)) {
          alert('Invalid meetLink format. It must be a valid URL starting with https://' );
          return;
        }
      }

      if(this.newUser.role == 'Operators') {
        console.log('this.role: ', this.role);
        if(this.userRole === 'Recruiters') {
          this.newUser.recruiterId = this.userId;
        } else if(this.userRole === 'Recruiter_Managers' || this.userRole === 'Admins') {
          if(!this.newUser.recruiterId || this.newUser.recruiterId == '') {
            alert('Recruiter is required for new operators.');
            return;
          }
        } else {
          if(!this.newUser.recruiterId || this.newUser.recruiterId == '') {
            alert('Recruiter is required for new operators.');
            return;
          }
        }
        if(this.newUser.startDate == '') {
          alert('start date is required for operators.');
          return;
        }
      }

      if(this.newUser.role == 'Recruiters') {
        if(!this.newUser.managerId || this.newUser.managerId == '') {
          alert('Manager is required for new recruiters.');
        }
      }

        // Timezone should always be passed, so we assume it's valid at this point.

        let endpoint = 'https://123avatars.com/v2/';
        if (this.newUser.role == 'Operators' || this.newUser.role == 'Roaming_Closer') {
          endpoint += 'sales-reps';
        } else {
          endpoint += 'managers';
        }

        this.newUser.userId = this.userId;

        // Send data to the server
        const response = await axios.post(endpoint, this.newUser);
        this.fetchSalesReps(); // Refresh the list after creating a new user
        this.showModal = false;
        if(response.status == 201) {
          if(this.newUser.role == 'Operators') {
            this.manager = response.data.manager;
            this.showNotification = true;
            // setTimeout(() => {
            //   this.showNotification = false;
            // }, 3000);
          } else {
            alert('User succesfully created');
            this.showModal = false;
          }
          this.fetchSalesReps();
          this.newUser = {
            userId: this.userId,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: 'Operators',
            managerId: '',
            recruiterId: '',
            dob: '',
            meetLink: '',
            startDate: '',
            whatsapp: '',
            timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
          };
          this.formattedPhoneNumber = '';
          this.formattedWhatsapp = '';
        }
      } catch (error) {
        console.error('Error creating user:', error);
       // this.showNotification = true;
        // this.error = true;
        //   setTimeout(() => {
        //     this.showNotification = false;
        //     this.error = false;
        //   }, 3000);
        alert('An error occurred while creating the user.');
      }
    },
    async updateUser() {
      try {
        // Validate required fields
        if (!this.newUser.firstName) {
          alert('First name is required.');
          return;
        }

        if (!this.newUser.lastName) {
          alert('Last name is required.');
          return;
        }

        if (!this.newUser.email) {
          alert('Email is required.');
          return;
        }

        // Email format validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(this.newUser.email)) {
          alert('Invalid email format.');
          return;
        }

        // Optional phone validation (must be in E.164 format if provided)
        //if (this.newUser.phone) {
          const phonePattern = /^\+[1-9]\d{1,14}$/; // E.164 format (supports optional '+')
          if (!phonePattern.test(this.newUser.phone)) {
            alert('Phone number must be in E.164 format (e.g., +1234567890).');
            return;
          }
      //  }

        if(this.newUser.role === 'Managers') {
          const urlPattern = /^https:\/\/meet\.google\.com\/[a-z]{3}-[a-z]{4}-[a-z]{3}$/;
          if(this.newUser.meetLink && !urlPattern.test(this.newUser.meetLink)) {
            alert('Invalid meetLink format. It must be a valid URL starting with https://' );
            return;
          }
        }

        // Timezone should always be passed, so we assume it's valid at this point.

        let endpoint = 'https://123avatars.com/v2/';
        if (this.newUser.role == 'Operators') {
          endpoint += `sales-reps/${this.selectedRep.id}`;
        } else {
          endpoint += 'managers';
        }

        // Send data to the server
        const response = await axios.put(endpoint, this.newUser);
        this.fetchSalesReps(); // Refresh the list after creating a new user
        this.showModal = false;
        this.newUser = {
          userId: this.userId,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          role: 'Operators',
          managerId: '',
          dob: '',
          meetLink: '',
          startDate: '',
          whatsapp: '',
          timezone: new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
        };
        this.formattedPhoneNumber = '';
        this.formattedWhatsapp = '';
        this.showSetDOBModal = false;
        alert('User updated successfully');
      } catch (error) {
        console.error('Error creating user:', error);
        this.showModal = false;
        alert('Failed to update user.');
        //this.showNotification = true;
        // this.error = true;
        //   setTimeout(() => {
        //     this.showNotification = false;
        //     this.error = false;
        //   }, 3000);
        alert('An error occurred while updating the user.');
      }
    },
    getUserRoleFromToken() {
      // Get the JWT from local storage
      const token = localStorage.getItem("userToken");

      if (!token) {
        return null; // Return null if no token is found
      }

      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = JSON.parse(atob(token.split('.')[1]));

      // Check if the 'cognito:groups' claim exists in the payload
      const groups = payload['cognito:groups'];

      if (!groups || groups.length === 0) {
        return null; // Return null if no groups are found
      }

      // Assuming the role is determined by the first group in the array
      return groups[0]; // Or you can adjust this to match your application's logic
    },
    getCognitoSub() {
      // Get the JWT from local storage
      const token = localStorage.getItem("user");

      if (!token) {
        return null; // Return null if no token is found
      }

      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = JSON.parse(atob(token.split('.')[1]));

      // Return the 'sub' value from the payload
      return payload.sub || null;
    },
    async removeUser(id) {
      try {
        await axios.delete(`https://123avatars.com/v2/sales-reps/${id}`);
        this.fetchSalesReps(); // Refresh the list after deleting a user
      } catch (error) {
        console.error('Error removing user:', error);
      }
    },
    async removeManager(id) {
      try {
        // Send a DELETE request to remove the manager
        await axios.delete(`https://123avatars.com/v2/managers/${id}`);
        
        // Refresh the list of sales reps and managers after deleting the manager
        this.fetchSalesReps(); 
      } catch (error) {
        console.error('Error removing manager:', error);
      }
    },
    async resetPassword() {
      if (this.passwordForm.newPassword !== this.passwordForm.confirmPassword) {
        this.passwordError = 'Passwords do not match';
        return;
      }
      try {
        await axios.post(`https://123avatars.com/v2/sales-reps/${this.selectedRep.id}/reset-password`, {
          newPassword: this.passwordForm.newPassword,
        });
        this.showResetPasswordModal = false;
        this.passwordForm = {
          newPassword: '',
          confirmPassword: ''
        };
      } catch (error) {
        console.error('Error resetting password:', error);
      }
    },
    formatPhone(phone) {
      const cleaned = ('' + phone).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return phone;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    calculateShelfLife(date) {
      const hireDate = new Date(date);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - hireDate);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    calculateAge(dob) {
      if (!dob) return '';
      const birthDate = new Date(dob);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    filterActiveReps(rep) {
      const query = this.searchQuery.toLowerCase();
      const fullName = `${rep.firstName} ${rep.lastName}`.toLowerCase();
      // return fullName.includes(query) ||
      //   rep.email.toLowerCase().includes(query) ||
      //   rep.phone.includes(query);
      return true;
    },
    editProfile(rep) {
      this.selectedRep = rep;
      this.newUser.firstName = rep.firstName;
      this.newUser.lastName = rep.lastName;
      this.newUser.email = rep.email;
      this.newUser.phone = rep.phone;
      this.newUser.managerId = rep.managerId;
      this.newUser.role = rep.role;
      this.newUser.meetLink = rep.meetLink;
      this.newUser.dob = rep.dob ? rep.dob.split('T')[0] : '';
      this.newUser.timezone = rep.timezone;
      this.newUser.whatsapp = rep.whatsapp;
      this.newUser.startDate = rep.start_date ? rep.start_date.split('T')[0] : '';

      this.formattedPhone = this.formatPhoneForDisplay(rep.phone);
      if(rep.whatsapp) {
        this.formattedWhatsapp = this.formatPhoneForDisplay(rep.whatsapp);
      }

      // Set `formattedPhone` to display the formatted phone number
     // this.formattedPhone = this.formatPhoneNumber(rep.phone);

      this.showSetDOBModal = true;
      console.log('this.newUser: ', this.newUser);
    },
    async setDOB() {
      if (this.selectedRep) {
        try {
          // Update the selectedRep's dob locally
          this.selectedRep.dob = this.dobForm.dob;

          // Send the updated DOB to the backend
          await axios.put(`https://123avatars.com/v2/sales-reps/${this.selectedRep.id}/dob`, {
            dob: this.dobForm.dob
          });

          this.showSetDOBModal = false;
        } catch (error) {
          console.error('Error updating DOB:', error);
        }
      }
    },
    async setUserRole() {
      this.userRole = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
      this.role = this.userRole;
      // if(this.userRole == 'Admins' && this.$route.path == '/users') {
      //   //target += '?id=' + this.$route.query.id;
      //   this.userRole = 'Admins';
      // }
      
      // if(this.userRole == 'Admins' && this.$route.path == '/manager-dashboard') {
      //   this.userRole = 'Managers';
      // }
      // if(this.userRole == 'Managers') {
      //   this.userRole = 'Managers';
      // }
    },
  },
  async mounted() {
    this.setUserRole();
    this.fetchSalesReps();
    this.fetchRecruiters();
  }
};
</script>

<style scoped>

#manager-dashboard .dashboard-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

#manager-dashboard .dashboard-link:hover {
  color: darkblue;
}

</style>