<template>
  <div class="stepper-container">
    <div class="step-content">
      <div class="content-container">
        <div style="padding: 5px;">
          <span class="font-bold text-1xl">Trackings</span>
        </div>
        <div style="padding: 5px;">
          <table class="table call-backs-table" aria-controls="tagstable">
            <thead>
              <tr>
                <th style="width: 10%;">Date</th>
                <th style="width: 18%;">Who Sent</th>
                <th style="width: 18%;">Sent</th>
                <th style="width: 18%;">Total Inbounds</th>
                <th style="width: 18%;">Negative Inbounds</th>
                <th style="width: 18%;">Positive Inbounds (Landed In Inbox)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in displayRows" :key="row.id" :data-tracking-id="row.id">
                <td>{{ row.date || '-' }}</td>
                <td>{{ row.who || '-' }}</td>
                <td>{{ row.sent?.toLocaleString() || '-' }}</td>
                <td>{{ row.totalInbounds || '-' }}</td>
                <td>{{ row.negativeInbounds || '-' }}</td>
                <td>{{ row.positiveInbounds || '-' }}</td>
              </tr>
              <tr class="font-bold bg-gray-100">
                <td>Total</td>
                <td>-</td>
                <td>{{ totals.sent.toLocaleString() }}</td>
                <td>{{ totals.totalInbounds }}</td>
                <td>{{ totals.negativeInbounds }}</td>
                <td>{{ totals.positiveInbounds }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      trackings: [{
        id: 1,
        date: 'December 1, 2024',
        who: 'John Steward',
        sent: 2000,
        totalInbounds: 100,
        negativeInbounds: 20,
        positiveInbounds: 80
      }, {
        id: 2,
        date: 'December 1, 2024',
        who: 'Russell Johnson',
        sent: 2500,
        totalInbounds: 120,
        negativeInbounds: 41,
        positiveInbounds: 79
      }, {
        id: 3,
        date: 'December 1, 2024',
        who: 'Been Greenfield',
        sent: 3507,
        totalInbounds: 140,
        negativeInbounds: 62,
        positiveInbounds: 78
      }]
    }
  },
  computed: {
    displayRows() {
      // Create array of 10 rows, fill empty spots with empty objects
      const rows = [...this.trackings];
      while (rows.length < 10) {
        rows.push({
          id: `empty-${rows.length}`
        });
      }
      return rows;
    },
    totals() {
      return this.trackings.reduce((acc, row) => {
        return {
          sent: acc.sent + row.sent,
          totalInbounds: acc.totalInbounds + row.totalInbounds,
          negativeInbounds: acc.negativeInbounds + row.negativeInbounds,
          positiveInbounds: acc.positiveInbounds + row.positiveInbounds
        };
      }, {
        sent: 0,
        totalInbounds: 0,
        negativeInbounds: 0,
        positiveInbounds: 0
      });
    }
  }
}
</script>

<style scoped>
.stepper-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.step-content {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e7eb;
}
.content-container {
  min-height: 200px;
  flex: 1;
  width: 100%;
  height: 100%;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th,
.table td {
  padding: 8px;
  border: 1px solid #e5e7eb;
  text-align: left;
}
.table thead th {
  background-color: #f9fafb;
  font-weight: bold;
}
</style>