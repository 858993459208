import { render, staticRenderFns } from "./Callbacks.vue?vue&type=template&id=09b8d0e2&scoped=true"
import script from "./Callbacks.vue?vue&type=script&lang=js"
export * from "./Callbacks.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b8d0e2",
  null
  
)

export default component.exports