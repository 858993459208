<template>
  <UIBox classbox="hello">
    <template v-slot:title>
      <span class="bold">Text Messages</span>
    </template>
    <template>
      <div class="table-fullwidth">
        <table class="table call-backs-table" aria-controls="tagstable">
          <thead>
            <tr>
              <th style="width: 1%;"></th>
              <th style="width: 19%;">Name</th>
              <th style="width: 20%;">Company</th>
              <th style="width: 20%;">Date</th>
              <th style="width: 20%;">Preview</th>
              <th style="width: 20%;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in messages" :data-sales-rep-id="row.id">
              <td></td>
              <td>{{ row.sales_rep }}</td>
              <td>{{ row.company_name }}</td>
              <td>{{ row.time }}</td>
              <td>
                <div class="flex items-center gap-2">
                  <chatSVG :size="16" :fill="roleColor()" />
                  <div>
                    {{ row.text }}
                  </div>
                </div>
              </td>
              <td>
                <div class="flex items-center gap-2">
                  <div style="cursor: pointer">
                    <starSVG :size="24" :fill="roleColor()" />
                  </div>
                  <button class="btn outline">
                    <phoneSVG :size="16" :fill="roleColor()" />
                  </button>
                  <button class="btn outline">
                    <emailSVG :size="16" :fill="roleColor()" />
                  </button>
                  <button class="btn outline">
                    <chatSVG :size="16" :fill="roleColor()" />
                  </button>
                  <button class="btn outline">
                    <tagsSVG :size="16" :fill="roleColor()" />
                  </button>
                  <button class="btn outline">
                    <launchindialerSVG :size="24" :fill="roleColor()" />
                  </button>                  
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bottom-input">

        <div class="per-page">
          <div class="select">
            Per Page
            <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchMessages">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <template>
          <div class="pagin">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage"
              aria-controls="tagstable" @change="fetchMessages">
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>
      </div>
    </template>
  </UIBox>
</template>

<script>

import axios from 'axios';
import UIBox from '../../components/uibox.vue';
import AuthService from '../../_services/AuthService';
import PaginleftdoubleSVG from '../../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from '../../components/SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from '../../components/SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from '../../components/SVG-icons/paginrightSVG.vue';
import starSVG from '../../components/SVG-icons/starSVG.vue';
import phoneSVG from '../../components/SVG-icons/phoneSVG.vue';
import emailSVG from '../../components/SVG-icons/emailSVG.vue';
import chatSVG from '../../components/SVG-icons/chatSVG.vue';
import tagsSVG from '../../components/SVG-icons/tagsSVG.vue';
import launchindialerSVG from '../../components/SVG-icons/launchindialerSVG.vue';

export default {
  name: "Inbox",
  components: {
    UIBox,
    PaginleftdoubleSVG,
    PaginleftSVG,
    PaginrightdoubleSVG,
    PaginrightSVG,
    starSVG,
    phoneSVG,
    emailSVG,
    chatSVG,
    tagsSVG,
    launchindialerSVG
  },
  data() {
    return {
      userId: '',
      role: '',
      messages: [],
      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 0
    };
  },
  methods: {
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role.toLowerCase()] || '#46A57C';
    },
    async fetchMessages() {
      let target = 'https://123avatars.com/v2/inbox';
      target += `?limit=${this.rowsPerPage}&offset=${this.currentPage}&role=${this.role}&id=${this.userId}`;
      const response = await axios.get(target);
      this.messages = response.data.data;
      this.totalRows = response.data.totalRows;
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    }
  },
  async mounted() {
    await this.initUser();
    await this.fetchMessages();
  }
};
</script>