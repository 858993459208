<template>
  <div class="stepper-container">
    <!-- Progress Steps -->
    <div class="steps-header">
      <div class="progress-line"></div>
      <div v-for="(step, index) in steps" :key="index" class="step-item" :class="{
        'active': currentStep === index,
        'completed': currentStep > index
      }" @click="goToStep(index)">
        <div :class="['step-circle', `step-circle${role?.toLocaleLowerCase()}`]">
          <span v-if="currentStep > index">✓</span>
          <span v-else>{{ String(index + 1).padStart(2, '0') }}</span>
        </div>
        <div class="step-icon">{{ step.icon }}</div>
        <div class="step-title">{{ step.title }}</div>
      </div>
    </div>

    <!-- Step Content -->
    <div class="step-content">
      <div class="content-container">
        <slot :name="'step-' + currentStep">
          <SelectLead v-if="currentStep === 0" />
        </slot>
      </div>
    </div>
    <!-- Navigation Buttons -->
    <div class="navigation-buttons">
      <button @click="previousStep" :disabled="currentStep === 0" class="nav-button btn solid">
        Previous
      </button>
      <button @click="nextStep" :disabled="currentStep === steps.length - 1" class="nav-button btn solid">
        {{ currentStep === steps.length - 1 ? 'Finish' : 'Next' }}
      </button>
    </div>
  </div>
</template>

<script>

import AuthService from '../../_services/AuthService.js';
import SelectLead from './SelectLead.vue';

export default {
  components: {
    SelectLead
  },
  props: {
    steps: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every(step =>
          'title' in step &&
          'icon' in step &&
          'description' in step
        );
      }
    },
    initialStep: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentStep: this.initialStep,
      role: '',
      userId: '',
    }
  },
  methods: {
    goToStep(index) {
      this.currentStep = index;
      this.$emit('step-change', index);
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.goToStep(this.currentStep + 1);
      }
    },
    previousStep() {
      if (this.currentStep > 0) {
        this.goToStep(this.currentStep - 1);
      }
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    }
  },
  async mounted() {
    await this.initUser();
  }
}
</script>

<style scoped>
.active {
  background-color: transparent;
}

.stepper-container {
  max-width: 100%;
  margin: 0 auto;
}

.steps-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-bottom: 3rem;
}

.progress-line {
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #e5e7eb;
  z-index: 1;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s ease;
}

.step-item:hover .step-circle {
  transform: scale(1.1);
}

.step-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  color: #6b7280;
  transition: all 0.3s ease;
}

.step-item.active .step-circleroaming_closer,
.step-item.completed .step-circleroaming_closer {
  background-color: #085F70;
  border-color: #085F70;
  color: white;
}

.step-item.active .step-circleadmins,
.step-item.completed .step-circleadmins {
  background-color: #983819;
  border-color: #983819;
  color: white;
}

.step-icon {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.step-title {
  font-size: 0.875rem;
  text-align: center;
  color: #111827;
  font-weight: 500;
  max-width: 120px;
  line-height: 1.2;
}

.step-content {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e7eb;
}

.content-container {
  min-height: 200px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.nav-button {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background-color: #7c3aed;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-button:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

.nav-button:not(:disabled):hover {
  background-color: #6d28d9;
}

/* Responsive styles */
@media (max-width: 1200) {
  .steps-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }

  .progress-line {
    top: 0;
    left: 16px;
    width: 2px;
    height: 100%;
  }

  .step-item {
    flex-direction: row;
    width: 100%;
    gap: 1rem;
  }

  .step-title {
    text-align: left;
  }
}
</style>