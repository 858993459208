<template>
  <div class="stepper-container">
    <div class="step-content">
      <div class="content-container">
        <div style="padding: 5px;">
          <span class="font-bold text-1xl">Premade Messages</span>
        </div>
        <div class="button-container">
          <button class="btn solid" @click="clearInput">New Message</button>
          <button class="btn solid" @click="saveMessage">Save</button>
        </div>
        <div class="textarea-container" style="padding: 10px;">
          <textarea 
            v-model="message"
            @input="handleInput"
            @keydown="handleKeydownWithLimit"
            placeholder="Write your message..."
            ref="messageInput"
          ></textarea>
          <div class="character-count">
            {{ remainingCharacters }} characters remaining
          </div>
          <!-- Suggestions Popup -->
          <div 
            v-if="showSuggestions && filteredKeywords.length > 0" 
            class="suggestions-popup"
            :style="{
              left: `${popupPosition.left}px`,
              top: `${popupPosition.top}px`
            }"
          >
            <div 
              v-for="(keyword, index) in filteredKeywords" 
              :key="keyword"
              :class="['suggestion-item', { active: selectedIndex === index }]"
              @click="selectKeyword(keyword)"
              @mouseover="selectedIndex = index"
            >
              {{ keyword }}
            </div>
          </div>
        </div>  
        <div class="table-wrapper" style="margin-bottom: 20px; padding: 10px;">
          <table class="table call-backs-table" aria-controls="tagstable">
            <thead>
              <tr>
                <th style="width: 89%;">Premade Message</th>
                <th style="width: 10%;">Date</th>
                <th style="width: 1;"></th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr v-for="(row, index) in messages" :key="row.id" :data-keyword-id="row.id" @click.stop="selectMessage(row)" style="cursor: pointer">
                <td>{{ row.message || '-' }}</td>
                <td>{{ row.created_date.toLocaleString() || '-' }}</td>
                <td @click.stop="deleteMessage(row.id)">
                  <closeSVG size="18" fill="red" style="cursor: pointer" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import closeSVG from '../SVG-icons/closeSVG.vue';

export default {
  components: {
    closeSVG
  },
  mounted() {
    this.fetchPremadeMessages();
  },
  data() {
    return {
      message: '',
      showSuggestions: false,
      keywords: ['@user', '@admin', '@manager', '@support', '@team'],
      filteredKeywords: [],
      selectedIndex: 0,
      currentMention: '',
      mentionStart: 0,
      popupPosition: {
        left: 0,
        top: 0
      },
      maxCharacters: 160,
      messages: [],
      selectedMessage: null
    }
  },
  computed: {
    remainingCharacters() {
      return this.maxCharacters - this.message.length;
    }
  },
  methods: {
    clearInput() {
      this.selectedMessage = null;
      this.message = '';
    },
    async selectMessage(row) {
      this.selectedMessage = row;
      this.message = this.selectedMessage.message;
    },
    async fetchPremadeMessages() {
      let target = `https://123avatars.com/v2/premade-messages`;
      const results = await axios.get(target);
      this.messages = results.data;
    },
    async deleteMessage(messageId) {
      let target = `https://123avatars.com/v2/premade-messages`;
      await axios.delete(target, { data: { id: messageId }});
      this.fetchPremadeMessages();
    },
    async saveMessage() {
      const target = `https://123avatars.com/v2/premade-messages`
      if (this.selectedMessage === null) {
        await axios.post(target, {
          message: this.message
        });
      } else {
        await axios.put(target, {
          id: this.selectedMessage.id,
          message: this.message
        });
      }
      await this.fetchPremadeMessages();
    },
    handleKeydownWithLimit(e) {
      // Handle special keys and suggestions
      if (this.showSuggestions) {
        this.handleKeydown(e);
        return;
      }

      // Allow control keys (backspace, delete, arrows, etc.)
      const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
      if (controlKeys.includes(e.key)) {
        return;
      }

      // Prevent typing if limit reached (except for control keys)
      if (this.remainingCharacters === 0 && !e.ctrlKey && !e.metaKey) {
        e.preventDefault();
      }
    },
    handleInput(e) {
      const cursorPosition = e.target.selectionStart;
      const textBeforeCursor = this.message.slice(0, cursorPosition);
      const lastAtSymbol = textBeforeCursor.lastIndexOf('@');

      if (lastAtSymbol !== -1) {
        const textAfterAt = textBeforeCursor.slice(lastAtSymbol);
        if (!textAfterAt.includes(' ')) {
          this.currentMention = textAfterAt;
          this.mentionStart = lastAtSymbol;
          this.filterKeywords(textAfterAt.slice(1));
          this.showSuggestions = true;
          
          this.$nextTick(() => {
            this.updatePopupPosition(e.target, lastAtSymbol);
          });
          return;
        }
      }

      this.showSuggestions = false;
    },

    updatePopupPosition(textarea, atIndex) {
      const { selectionStart, selectionEnd } = textarea;
      
      // Get the coordinates of the cursor
      const coordinates = textarea.getClientRects()[0];
      const caretCoordinates = getCaretCoordinates(textarea, selectionStart);
      
      this.popupPosition = {
        left: coordinates.left + caretCoordinates.left,
        top: coordinates.top + caretCoordinates.top + caretCoordinates.height + 1
      };
    },

    // Add this helper function for getting caret coordinates
      getCaretCoordinates(element, position) {
        const div = document.createElement('div');
        const style = getComputedStyle(element);
        
        div.style.cssText = `
          position: absolute;
          visibility: hidden;
          height: auto;
          width: ${element.offsetWidth}px;
          white-space: pre-wrap;
          overflow-wrap: break-word;
          font: ${style.font};
          padding: ${style.padding};
          border: ${style.border};
          line-height: ${style.lineHeight};
        `;
        
        const text = element.value.slice(0, position);
        const span = document.createElement('span');
        
        div.textContent = text;
        span.textContent = element.value.slice(position) || '.';
        div.appendChild(span);
        document.body.appendChild(div);
        
        const coordinates = {
          top: div.offsetHeight - span.offsetHeight,
          left: span.offsetLeft,
          height: parseInt(style.lineHeight)
        };
        
        document.body.removeChild(div);
        return coordinates;
      },
    filterKeywords(query) {
      this.selectedIndex = 0;
      this.filteredKeywords = this.keywords.filter(keyword =>
        keyword.toLowerCase().includes(query.toLowerCase())
      );
    },

    selectKeyword(keyword) {
      const beforeMention = this.message.slice(0, this.mentionStart);
      const afterMention = this.message.slice(this.mentionStart + this.currentMention.length);
      this.message = beforeMention + keyword + afterMention;
      this.showSuggestions = false;
      
      this.$nextTick(() => {
        const newPosition = this.mentionStart + keyword.length;
        this.$refs.messageInput.setSelectionRange(newPosition, newPosition);
        this.$refs.messageInput.focus();
      });
    },

    handleKeydown(e) {
      if (!this.showSuggestions) return;

      switch(e.key) {
        case 'ArrowDown':
          e.preventDefault();
          this.selectedIndex = (this.selectedIndex + 1) % this.filteredKeywords.length;
          break;
        case 'ArrowUp':
          e.preventDefault();
          this.selectedIndex = this.selectedIndex - 1 < 0 
            ? this.filteredKeywords.length - 1 
            : this.selectedIndex - 1;
          break;
        case 'Enter':
          e.preventDefault();
          if (this.filteredKeywords.length > 0) {
            this.selectKeyword(this.filteredKeywords[this.selectedIndex]);
          }
          break;
        case 'Escape':
          this.showSuggestions = false;
          break;
      }
    }
  }
}
</script>

<style scoped>
.button-container {
  display: flex;
  gap: 10px; /* Space between buttons */
  margin-bottom: 10px;
}
.character-count {
  text-align: right;
  color: #000;
  font-size: 0.875rem;
  padding: 2px 5px;
}
.stepper-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.step-content {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e7eb;
}

.content-container {
  min-height: 200px;
  flex: 1;
  width: 100%;
  height: 100%;
}

.textarea-container {
  position: relative;
  width: 100%;
}

textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  resize: vertical;
}

.suggestions-popup {
  position: absolute; /* Change back to absolute */
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  /* Remove the transform property */
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
}

.suggestion-item:hover,
.suggestion-item.active {
  background-color: #f3f4f6;
}

.btn.solid {
  background-color: #C0392B;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}
</style>