import { render, staticRenderFns } from "./Stepper.vue?vue&type=template&id=3a2874a1&scoped=true"
import script from "./Stepper.vue?vue&type=script&lang=js"
export * from "./Stepper.vue?vue&type=script&lang=js"
import style0 from "./Stepper.vue?vue&type=style&index=0&id=3a2874a1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2874a1",
  null
  
)

export default component.exports