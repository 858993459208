<template>
    <div>
      <!-- Header Row -->
      <header class="d-flex">
        <!-- Dial-pad button -->
        <button id="popoverpad" class="btn outline icon ms-auto">
          <PhoneSVG />
        </button>
      </header>
        <div>
          <Callbacks :prospect="company" @callbacks-closed="handleCallbacksClosed" v-if="showCallbacks"></Callbacks>
        </div>

        <!-- Number Pad Popover -->
        <b-popover ref="popoverPad" target="popoverpad" triggers="click" placement="bottom" custom-class="dialer-popover">
          <div class="content">
            <div class="header">
              <h3 class="title" id="dialer_modal_label">Dialer</h3>
              <button class="btn" @click="closeDialPad()">
                <CloseSVG />
              </button>
            </div>
            <div class="body">
              <table id="dialer_table">
                <tr>
                  <td id="dialer_input_td" colspan="3"><input type="text" placeholder="3212345678" class="pad noSelect" v-model="phoneNumber"></td>
                </tr>
                <tr class="dialer_num_tr">
                  <td class="dialer_num" @click="dialerClick('dial', 1)">1</td>
                  <td class="dialer_num" @click="dialerClick('dial', 2)">2</td>
                  <td class="dialer_num" @click="dialerClick('dial', 3)">3</td>
                </tr>
                <tr class="dialer_num_tr">
                  <td class="dialer_num" @click="dialerClick('dial', 4)">4</td>
                  <td class="dialer_num" @click="dialerClick('dial', 5)">5</td>
                  <td class="dialer_num" @click="dialerClick('dial', 6)">6</td>
                </tr>
                <tr class="dialer_num_tr">
                  <td class="dialer_num" @click="dialerClick('dial', 7)">7</td>
                  <td class="dialer_num" @click="dialerClick('dial', 8)">8</td>
                  <td class="dialer_num" @click="dialerClick('dial', 9)">9</td>
                </tr>
                <tr class="dialer_num_tr">
                  <td class="dialer_num" @click="dialerClick('dial', 0)">#</td>
                  <td class="dialer_num" @click="dialerClick('dial', 0)">0</td>
                  <td class="dialer_num" @click="dialerClick('dial', 0)">*</td>
                </tr>
                <tr class="dialer_num_tr">
                  <td align-self="center" class="dialer_del_td bottom-buttons" id="dialer_clear_btn_td" @click="dialerClick('delete', 'delete')">
                    <EraseSVG />
                  </td>
                  <td class="dialer_call_td bottom-buttons" id="dialer_call_btn_td" @click="makeDirectCall">
                    <PhoneSVG v-if="!call"/>
                    <PhoneHangUpSVG v-if="call"/>
                  </td>
                  <td class="dialer_del_td bottom-buttons" id="dialer_del_btn_td" @click="dialerClick('clear', 'clear')">
                    <CloseSVG />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </b-popover>
        
      <main>
        <audio id="telnyx-audio" autoplay></audio>
        <audio id="ringback-audio" autoplay controls loop style="visibility:hidden;"></audio>
        <div class="container-fluid">
          <div class="row">
            <!-- Left Nav Column -->
            <div class="sidebar col-3">
              <a href="#" class="side-nav" :class="{ active: currentView === 'panel' }" @click="updateView('panel')">Panel</a>
              <a href="#" class="side-nav" :class="{ active: currentView === 'scoreboard' }" @click="updateView('scoreboard')">Scoreboard</a>
              <a href="#" class="side-nav" :class="{ active: currentView === 'users' }" @click="updateView('users')">Users</a>
              <a href="#" class="side-nav" :class="{ active: currentView === 'calls-report' }" @click="updateView('calls-report')">Calls Report</a>
              <a href="#" class="side-nav" :class="{ active: currentView === 'callback-report' }" @click="updateView('callback-report')">Call Backs</a>
              <a href="#" class="side-nav" :class="{ active: currentView === 'daily-qa' }" @click="updateView('daily-qa')">Daily Q&A</a>
              <!-- <a href="#" class="side-nav" :class="{ active: currentView === 'settings' }" @click="updateView('settings')">Settings</a> -->
            </div>
            <div class="content col-9">
              <!-- Main Content Area -->
              <div v-if="currentView == 'panel'">
                <Panel @init-call="initCall"></Panel>
              </div>
              <div v-if="currentView == 'scoreboard'">
                <Scoreboard @missed-tos="openMissedTOs"></Scoreboard>
              </div>
              <div v-if="currentView === 'users'">
                <Users></Users>
              </div>
              <div v-else-if="currentView === 'calls-report'">
                <CallsReport :salesRepId="salesRepIdCallsFilter" :toCallsFilter="toCallsFilter"></CallsReport>
              </div>
              <div v-else-if="currentView === 'callback-report'">
                <CallbackReport></CallbackReport>
              </div>
              <div v-else-if="currentView === 'daily-qa'">
                <QALink></QALink>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { TelnyxRTC } from '@telnyx/webrtc';
  import  CallsReport  from "./CallReports.vue";
  import Chat from "../../components/sales/Chat.vue";
  import GetPaid from "./GetPaid.vue";
  import Callbacks from "./Callbacks.vue";
  import CallbackReport from "./CallbacksReport.vue";
  import Users from "./Users.vue";
  import Panel from "../../components/sales/Panel.vue";
  import Scoreboard from "../../components/sales/scoreboard.vue";
  import QALink from "./QALink.vue";
  import SocketService from '../../components/sales/socketService.js';
  import PhoneSVG from '../../components/SVG-icons/phoneSVG.vue';
  import CloseSVG from '../../components/SVG-icons/closeSVG.vue';
  import EraseSVG from '../../components/SVG-icons/eraseSVG.vue';
  import PhoneHangUpSVG from '../../components/SVG-icons/phonehangupSVG.vue';
  import AuthService from '@/_services/AuthService';
  
  export default {
    components: {  'CallsReport': CallsReport, 'Chat': Chat, 'GetPaid': GetPaid, 'Callbacks': Callbacks, 'CallbackReport': CallbackReport, 'Users': Users, 'Scoreboard': Scoreboard, 'QALink': QALink, 'Panel': Panel, PhoneSVG, PhoneHangUpSVG, CloseSVG, EraseSVG, },
    data() {
      return {
        currentView: 'panel',
        stats: {
          today: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
          thisWeek: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
          lastWeek: { talkTime: '', totalCalls: 0, inboundCalls: 0, outboundCalls: 0 },
          sales: { talkTime: 0, totalCalls: 0, inboundCalls: 0, outboundCalls: 0 }
        },
        searchQuery: '',
        dateFilter: 'All',
        typeFilter: 'All',
        milestoneFilter: 'All',
        timezoneFilter: 'All',
        salesRepFilter: 'All',
        shelfLifeFilter: 'All',
        hoursFilter: 'Show Only During Hours',
        customStartDate: '',
        customEndDate: '',
        rowsPerPage: 50,
        currentPage: 1,
        leads: [],
        totalRows: 0,
        sortDirection: 'asc',
        showOverlay: false,
        showNotesPopup: false,
        showMessagesPopup: false,
        notesCompanyName: '',
        messagesCompanyName: '',
        currentNotes: [],
        newNoteText: '',
        notesData: {},
        filteredRows: [],
        currentLeadId: null,
        totalCount: 0,
        scriptLink: '',
        signupLink: '',
        qaLink: '',
        trainingLockerLink: '',
        dialerActive: 'OFF',
        dialerText: 'Start Dialer',
        playingCallId: null,
        audio: null,
        outboundHuman: false,
        callDestination: '',
        includeVideo: false,
        enableDebugging: false,
        authentication: 'credential',
        sipUsername: 'cmharrisiii22686',
        password: '5khcqfTE',
        callerIdName: '',
        callerIdNumber: '',
        clientState: '',
        telnyxRTC: null,
        call: null,
        dialpad: [
          ['1', '2', '3'],
          ['4', '5', '6'],
          ['7', '8', '9'],
          ['*', '0', '#']
        ],
        username: null,
        password: null,
        timer: null,
        offTimer: null,
        connectedTimer: null,
        currentCallTimer: null,
        elapsedTime: 0,
        offElapsedTime: 0,
        connectedElapsedTime: 0,
        currentCallElapsedTime: 0,
        status: 'OFF',
        ignore: false,
        currentProspectNumber: '',
        loggedTime: 0,
        showTimeModal: false,
        notes: [],
        showNotification: false,
        salesNumber: 0,
        prospect: null,
        company: {
          company_name: '-',
          phone: '-',
          address: '-',
          city: '-',
          state: '-',
          zipcode: '-',
          owner: '-',
          owner_cell: '-',
          owner_email: '-'
        },
        showDialerModal: false,
        phoneNumber: '',
        sipDid: '',
        paused: false,
        messages: [],
        calls: [],
        currentSalesRepId: '',
        managerId: '',
        currentSalesRepName: '',
        isListen: false,
        bargeCall: '',
        localStream: null,
        outboundStream: null,
        listeningCall: null,
        showCallbacks: false,
        context: null,
        frequencies: {
          '1': [697, 1209],
          '2': [697, 1336],
          '3': [697, 1477],
          '4': [770, 1209],
          '5': [770, 1336],
          '6': [770, 1477],
          '7': [852, 1209],
          '8': [852, 1336],
          '9': [852, 1477],
          '*': [941, 1209],
          '0': [941, 1336],
          '#': [941, 1477],
        },
        bargeContext: null,
        microphoneNode: null,
        microphoneStream: null,
        prospectNode: null,
        prospectStream: null,
        managerStream: null,
        missedCalls: 0,
        voicemails: 0,
        objections: null,
        activePanel: null,
        upcomingCallback: null,
        lastCompany: null,
        lastConnectedDuration: 0,
        salesRepIdCallsFilter: '',
        toCallsFilter: 'All',
        manager: null,
        role: '',
        managerId: '',
        phoneNumber: ''
      };
    },
    computed: {
      loggedFormattedTime() {
        const hours = Math.floor(this.elapsedTime / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((this.elapsedTime % 3600) / 60).toString().padStart(2, '0');
        const seconds = (this.elapsedTime % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      },
      loggedOffFormattedTime() {
        const hours = Math.floor(this.offElapsedTime / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((this.offElapsedTime % 3600) / 60).toString().padStart(2, '0');
        const seconds = (this.offElapsedTime % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      },
      connectedFormattedTime() {
        const hours = Math.floor(this.connectedElapsedTime / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((this.connectedElapsedTime % 3600) / 60).toString().padStart(2, '0');
        const seconds = (this.connectedElapsedTime % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      },
      connectedCallFormattedTime() {
        const hours = Math.floor(this.currentCallElapsedTime / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((this.currentCallElapsedTime % 3600) / 60).toString().padStart(2, '0');
        const seconds = (this.currentCallElapsedTime % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      }
    },
    methods: {
      async playRingback() {
        const audioElement = document.getElementById('ringback-audio');
        audioElement.src = 'https://123avatars.com/uploads/US_ringback_tone.ogg`';
        await audioElement.play();
        console.log('audioElement: ', audioElement);
      },
      async initCall(number) {
        console.log('number: ', number);
        this.phoneNumber = number;
        this.$refs.popoverPad.$emit('open');
        this.makeDirectCall();
      },
      closeDialPad() {
        if(this.call) {
            this.call.hangup();
            this.call = null;
          }
          this.$refs.popoverPad.$emit('close');
      },
      openMissedTOs(salesRepId) {
        console.log('salesRepId: ', salesRepId);
        this.salesRepIdCallsFilter = salesRepId;
        this.toCallsFilter = 'Missed';
        this.updateView('calls-report');
      },
      async fetchObjections() {
        try {
          const response = await axios.get('https://123avatars.com/v2/objections');
          this.objections = response.data;
        } catch (error) {
          console.error('Error fetching objections:', error);
        }
      },
      togglePanel(index) {
        this.activePanel = this.activePanel === index ? null : index;
      },
      isActive(index) {
        return this.activePanel === index;
      },
      // Function to change the first input stream
      setMicrophoneStream(newStream) {
        if (this.microphoneStream) {
          this.microphoneStream.disconnect(this.microphoneNode);
        }
        this.microphoneStream = this.bargeContext.createMediaStreamSource(newStream);
        this.microphoneStream.connect(this.microphoneNode);
      },
      // Function to change the second input stream
      setProspectStream(newStream) {
        console.log("prospectStream: ", this.prospectStream);
        console.log("newStream: ", newStream);
        if (this.prospectStream) {
          this.prospectStream.disconnect(this.prospectNode);
        }
        this.prospectStream = this.bargeContext.createMediaStreamSource(newStream);
        console.log("prospectStream2: ", this.prospectStream);
        this.prospectStream.connect(this.prospectNode);
        console.log("bargeContext: ", this.bargeContext);
      },
      async openBarge() {
        this.bargeContext = new (window.AudioContext || window.webkitAudioContext)();
        this.microphoneNode = this.bargeContext.createGain();
        this.prospectNode = this.bargeContext.createGain();
        this.managerStream = this.bargeContext.createMediaStreamDestination();
  
        this.microphoneNode.connect(this.managerStream);
        this.prospectNode.connect(this.managerStream);
  
        // set to microphone stream
        this.setMicrophoneStream(this.localStream);
  
        if(this.call) {
          this.setProspectStream(this.call.remoteStream);
        }
  
        console.log("bargeContext: ", this.bargeContext);
  
        console.log("this.listeningCall: ", this.listeningCall);
        console.log("managerStream: ", this.managerStream);
        this.managerStream.stream.getTracks().forEach((track) => {
          console.log("localStream: ", this.managerStream.stream.getTracks());
          this.listeningCall.peer.instance.addTrack(track, this.managerStream.stream);
        });
  
        // set to call stream
  
        // need function to modify call stream
  
  
      },
      async scheduleCallback() {
        console.log("company: ", this.company);
        this.showCallbacks = true;
      },
      async handleCallbacksClosed() {
        this.showCallbacks = false;
      },
      async dialNumber(phone) {
        this.phoneNumber = phone;
        this.showDialerModal = true;
      },
      async getUserMedia() {
        try {
          console.log("devices: ", await navigator.mediaDevices.enumerateDevices());
          this.localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
         // console.log("localStream: ", localStream);
          console.log("localStream123: ", this.localStream);
        } catch (err) {
          console.error('Error getting user media:', err);
        }
      },
      searchLead() {
        const url = `https://www.google.com/search?q=${encodeURIComponent(this.formatPhoneNumber(this.company.phone))}`;
        window.open(url, '_blank');
      },
      formatPhoneNumber(phoneNumber) {
          phoneNumber = phoneNumber.toString();
          const areaCode = phoneNumber.slice(0, 3);
          const centralOfficeCode = phoneNumber.slice(3, 6);
          const stationCode = phoneNumber.slice(6, 10);
          const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
          return formattedPhoneNumber;
      },
      async blacklist() {
        try {
  
          this.company = {
          company_name: this.prospect.company_name,
          phone: this.prospect.phone,
          address: '-',
          city: '-',
          state: this.prospect.state,
          zipcode: '-',
          owner: '-',
          owner_cell: this.formatPhoneNumber(this.prospect.phone),
          owner_email: '-'
        };
  
          let email = this.company.owner_email ? this.company.owner_email : '';
          let response = await axios
          .get(
            "https://123avatars.com/backend/setAvatarBlacklist.php?u=" + this.userId + "&no=" + this.company.phone + "&label=" + '1'+ "&no2=" + '' + "&mail=" + email + "&mail2=" + this.mail2
          );
          if(response.status == 200) {
            this.$toasted.show(" Added to blacklist successfully ", {
              theme: "outline",
              position: "top-right",
              type: "success",
              icon: "check",
              duration: 2000,
            });
          } else {
            this.$toasted.show("Failed to add to blacklist.", {
              theme: "outline",
              position: "top-right",
              type: "danger",
              icon: "cross",
              duration: 2000,
            });
          }
          this.nextCallButton();
        } catch (err) {
          console.error("error adding company to blacklist: ", err);
        }
      },
      async sendHeartbeat() {
        try {
          const payload = {
            id: this.$route.query.id,
            loggedInTime: this.elapsedTime,
            talkTime: this.connectedElapsedTime,
            currentCallTime: this.currentCallElapsedTime
          };
          const url = 'https://123avatars.com/v2/heartbeat';
  
          //const response = await axios.post(url, payload);
          // Send the data using navigator.sendBeacon
        // navigator.sendBeacon(url, JSON.stringify(payload));
        } catch (err) {
          console.error("Error sending heartbeat.", err);
        }
      
      },
      startHeartbeat() {
        // Send an initial heartbeat
        this.sendHeartbeat();
  
        // Set up a recurring heartbeat every 5 minutes (300000 milliseconds)
        if(!this.heartbeatInterval) {
          this.heartbeatInterval = setInterval(this.sendHeartbeat, 2000);
        }
      },
      stopHeartbeat() {
        if (this.heartbeatInterval) {
          clearInterval(this.heartbeatInterval);
          this.heartbeatInterval = null;
        }
      },
      sendMessage() {
  
      },
      openLastProspect() {
  
      },
      isFromSalesRep(message) {
        return message.inbound === "0"; // Assuming inbound "0" means from sales rep
      },
      getSenderName(message) {
        return this.isFromSalesRep(message) ? 'You' : 'ABC Plumbing'; // Replace 'ABC Plumbing' with appropriate logic
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-US', options);
      },
      fetchMessages(prospect) {
        this.messagesCompanyName = `Messages with ${prospect.company_name}`;
        this.showOverlay = true;
        this.showMessagesPopup = true;
        this.messages = []; // Reset messages array
  
        const avatarId = prospect.avatar_id;
        const leadId = prospect.lead_id;
  
        console.log("url: ", `https://123avatars.com/backend/lead_conversation.php?avatar_id=${prospect.avatar_id}&lead_id=${prospect.lead_id}`);
        axios.get(`https://123avatars.com/backend/lead_conversation.php?avatar_id=${prospect.avatar_id}&lead_id=${prospect.lead_id}`, {
          params: {
            avatar_id: avatarId,
            lead_id: leadId
          }
        })
        .then(response => {
          this.messages = response.data;
          console.log("response.data: ", response.data);
          console.log("messages: ", this.messages);
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
      },
      pauseButton() {
        this.paused = !this.paused;
      },
      callIntoVoicemail() {
        this.phoneNumber = '*98';
        this.makeDirectCall();
        this.showDialerModal = true;
        setTimeout(() => {
          this.call.dtmf('1234#');
          setTimeout(() => {
            //this.call.dtmf('1');
          }, 1000);
        }, 12000);
      },
      async playTone(digit) {
        if (!this.context) {
          this.context = new (window.AudioContext || window.webkitAudioContext)();
        }
        const oscillator1 = this.context.createOscillator();
        const oscillator2 = this.context.createOscillator();
        const gainNode = this.context.createGain();
  
        gainNode.gain.value = 0.1; // Set volume
  
        oscillator1.frequency.setValueAtTime(this.frequencies[digit][0], this.context.currentTime);
        oscillator2.frequency.setValueAtTime(this.frequencies[digit][1], this.context.currentTime);
  
        oscillator1.connect(gainNode);
        oscillator2.connect(gainNode);
        gainNode.connect(this.context.destination);
  
        oscillator1.start();
        oscillator2.start();
  
        setTimeout(() => {
          oscillator1.stop();
          oscillator2.stop();
        }, 300); // Play the tone for 300ms
      },
      async dialerClick(type, digit) {
        console.log('type: ', type);
        console.log('digit: ', digit);
        switch(type) {
          case 'dial': 
            console.log('this.status: ', this.status);
            if(this.dialerStatus == 'Connected') {
              this.phoneNumber += digit.toString();
              console.log('call: ', this.call);
              console.log('digit: ', this.call.dtmf); 
              this.playTone(digit);
              let result = await this.call.dtmf(digit.toString());
              console.log('result: ', result);
            } else {
              this.phoneNumber += digit.toString();
              this.playTone(digit);
            }
  //          this.call.dtmf(digit);
            break;
          case 'delete':
            this.deleteLastDigit();
            break;
          case 'clear':
            this.clearPhoneNumber();
            break;
          default:
            console.error("Unknown keypad action.");
        }
      },
      clearPhoneNumber() {
        this.phoneNumber = '';
      },
      deleteLastDigit() {
        this.phoneNumber = this.phoneNumber.slice(0, -1);
      },
      async makeDirectCall() {
        const telnyxRTC = this.telnyxRTC;
        if (telnyxRTC) {
          let toNum = this.phoneNumber;
          if (toNum.length === 10) {
            toNum = '+1' + toNum;
          }
          console.log("Dialing number:", toNum);

          try {
            let response = await axios.post('https://123avatars.com/v2/add-outbound-call', {
              fromNum: this.sipDid,
              toNum: toNum,
              salesRepId: await AuthService.getCognitoSub(),
              callControlId: ''
            });

            this.call = telnyxRTC.newCall({
              destinationNumber: toNum,
              callerNumber: this.sipDid,
              record: "record-from-answer",
              record_format: "mp3",
              record_channels: "single",
              webhook_url: 'https://123avatars.com/v2/telnyx-callback-dialer'
            });         
            console.log("call: ", this.call);
          } catch (err) {
            console.error("error initing call: ", err);
          }
        } else {
          console.error("TelnyxRTC is not initialized.");
        }
      },
      updateDialerModal() {
        if(this.showDialerModal) {
          if(this.call) {
            this.call.hangup();
            this.call = null;
          }
        }
        this.showDialerModal = !this.showDialerModal;
        console.log("showDialerModal: ", this.showDialerModal);
      },
      updateView(currentView) {
        this.currentView = currentView;
        if(this.currentView == 'training' || this.currentView == 'how-to' || this.currentView == 'recorded-calls' || this.currentView == 'recorded-videos' || this.currentView == 'script') {
          setTimeout(() => {
            this.loadTypeformScript();
          }, 100);
        }
        if(this.currentView == 'ask-ai') {
          setTimeout(() => {
            this.loadAskAIScript()
          }, 100);
        }
      },
      loadTypeformScript() {
        if (!document.getElementById('typeform-embed-script')) {
          let script = document.createElement('script');
          script.src = 'https://embed.typeform.com/next/embed.js';
          script.id = 'typeform-embed-script';
          script.async = true;
          script.onload = this.initializeTypeform;
          document.head.appendChild(script);
        } else {
          let script = document.getElementById('typeform-embed-script');
          document.head.removeChild(script);
          this.loadTypeformScript();
        }
      },
      initializeTypeform() {
        // Typeform script will automatically initialize any divs with data-tf-live attribute
        console.log("Typeform script loaded and initialized");
      },
      async fetchSchedule() {
        try {
          const response = await axios.get('https://123avatars.com/v2/get-schedule', {
            params: {
              userId: '123009',
              type: 'Wakeups'
            }
          });
  
          const schedule = response.data;
          const currentTime = new Date();
  
          // Convert openTime and closeTime to local time
          const openTime = new Date(currentTime);
          const [openHours, openMinutes] = schedule.openTime.split(':');
          openTime.setUTCHours(openHours, openMinutes, 0, 0);
  
          const closeTime = new Date(currentTime);
          const [closeHours, closeMinutes] = schedule.closeTime.split(':');
          closeTime.setUTCHours(closeHours, closeMinutes, 0, 0);
  
          console.log("openTime: ", openTime);
          console.log("closeTime: ", closeTime);
  
          // Adjust for cases where closeTime is past midnight
          if (closeTime < openTime) {
            closeTime.setDate(closeTime.getDate() + 1);
          }
  
          // Check if current time is within the open and close times
          if (currentTime >= openTime && currentTime <= closeTime) {
            this.showTimeModal = true;
          } else {
            this.showTimeModal = true;
          }
  
          console.log("showTimeModal: ", this.showTimeModal);
  
        } catch (error) {
          console.error('Error fetching schedule:', error);
        }
      },
      startScheduleCheck() {
        // Call fetchSchedule immediately
        this.fetchSchedule();
  
        // Set interval to call fetchSchedule every minute
        setInterval(this.fetchSchedule, 60000);
      },
      async loadExternalScript() {
        // let recaptchaScript = document.createElement('script')
        // recaptchaScript.setAttribute('src', 'https://cdn.customgpt.ai/js/chat.js')
        // document.head.appendChild(recaptchaScript)
        // recaptchaScript.onload = async () => {
        //   await CustomGPT.init({ p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2" });
        //   CustomGPT.hide();
        // }
      },
      async loadAskAIScript() {
        // try {
        //   await CustomGPT.init({ div_id: "customgpt_chat", p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2" });
        // } catch (err) {
        //   console.error("error loading new GPT: ", err);
        // }
        // (function() {
        //   let embeddedAIScript = document.createElement('script');
        //   embeddedAIScript.setAttribute('src', 'https://cdn.customgpt.ai/js/embed.js');
        //   embeddedAIScript.setAttribute('div_id', 'customgpt_chat');
        //   embeddedAIScript.setAttribute('div_id', '36587');
        //   embeddedAIScript.setAttribute('div_id', '876cdea81afe212c597fe461d006f0a2');
        //   document.head.appendChild(embeddedAIScript);
        //   embeddedAIScript.onload = async () => {
        //     // Ensure that getDomain is not already declared in this scope
        //     if (typeof getDomain === 'undefined') {
        //       function getDomain() {
        //         // Function implementation
        //       }
        //     }
        //     // await CustomGPT.init({ div_id: "customgpt_chat", p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2" });
        //   };
        // })();
      },
      async loadEmbeddedExternalScript() {
        let embeddedScript = document.createElement('script')
        embeddedScript.setAttribute('src', 'https://embed.typeform.com/next/embed.js')
        document.head.appendChild(embeddedScript)
        embeddedScript.onload =  () => {
          console.log("embeddedScriptLoaded");
        }
      },
      convertToLocalTime(utcDateTime) {
        var local = new Date(utcDateTime);
        return local;
      },
      convertToLocalTimeFormatted(utcDateTime) {
        const local = new Date(utcDateTime);
        let hours = local.getHours();
        const minutes = local.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? 12 : 12;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutesStr} ${ampm}`;
      },
      getTimezone() {
        // Get the local timezone
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // List of US timezones
        const usTimezones = [
          'America/New_York',    // EST/EDT
          'America/Chicago',     // CST/CDT
          'America/Denver',      // MST/MDT
          'America/Phoenix',     // MST (no DST)
          'America/Los_Angeles', // PST/PDT
          'America/Anchorage',   // AKST/AKDT
          'America/Adak',        // HST/HDT
          'Pacific/Honolulu',    // HST (no DST)
        ];

        // Check if the local timezone is a US-based timezone
        if (usTimezones.includes(localTimezone)) {
          return localTimezone;
        }

        // If not a US timezone, return PST/PDT
        return 'America/Los_Angeles';
      },
      async markLoggedInTime() {
        try {
          const salesRepId = this.$route.query.id;
          const currentTime = new Date();
          const timezone = this.getTimezone();
  
          const response = await fetch('https://123avatars.com/v2/markLoginTime', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: this.userId,
              salesRepId,
              timestamp: currentTime.toISOString(),
              loginLogout: 1,
              date: currentTime.toISOString().split('T')[0],
              timezone,
            }),
          });
  
          if (response.ok) {
            const data = await response.json();
            this.startTime = new Date(data.startTime);
          } else {
            console.error('Error logging rep logged time: ', await response.text());
          }
        } catch (err) {
          console.error('Error logging rep logged time: ', err);
        }
      },
      async saveLoggedTime(elapsedTime) {
        try {
          const salesRepId = this.$route.query.id;
          const currentTime = new Date();
  
          const response = await fetch('https://123avatars.com/v2/saveLoggedTime', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: this.userId,
              salesRepId,
              timestamp: currentTime.toISOString(),
              loginLogout: 0,
              duration: this.elapsedTime,
              connectedDuration: this.connectedElapsedTime,
              date: currentTime.toISOString().split('T')[0],
            }),
          });
  
          if (response.ok) {
            console.log('Successfully logged out time');
          } else {
            console.error('Error logging rep time in DB: ', await response.text());
          }
        } catch (err) {
          console.error('Error logging rep time in DB: ', err);
        }
      },
      getTimeDifferenceInSeconds(timeString) {
        // Parse the input time string into a Date object
        console.log("timeString: ", timeString);
        const pastDate = new Date(timeString);
  
        console.log("pastDate: ", pastDate);
  
        // Extract the UTC time components for the past date
        const pastTime = Date.UTC(
          pastDate.getUTCFullYear(),
          pastDate.getUTCMonth(),
          pastDate.getUTCDate(),
          pastDate.getUTCHours(),
          pastDate.getUTCMinutes(),
          pastDate.getUTCSeconds(),
          pastDate.getUTCMilliseconds()
        );
  
        // Get the current date and time in UTC
        const currentDate = new Date();
        const currentTime = Date.UTC(
          currentDate.getUTCFullYear(),
          currentDate.getUTCMonth(),
          currentDate.getUTCDate(),
          currentDate.getUTCHours(),
          currentDate.getUTCMinutes(),
          currentDate.getUTCSeconds(),
          currentDate.getUTCMilliseconds()
        );
  
        // Calculate the difference in milliseconds
  
        const timeDifferenceInMillis = Math.abs(currentTime - pastTime);
  
        console.log("timeDiffMili: ", timeDifferenceInMillis);
  
        // Convert the difference from milliseconds to seconds
        const timeDifferenceInSeconds = timeDifferenceInMillis / 1000;
  
        return timeDifferenceInSeconds;
      },
      async loadLastLoggedTime() {
        try {
          const salesRepId = this.$route.query.id;
  
          const response = await fetch(`https://123avatars.com/v2/loadLastLoggedTime?salesRepId=${salesRepId}&userId=${this.userId}`);
  
          if (response.ok) {
            const data = await response.json();
            let elapsedOffTime = this.getTimeDifferenceInSeconds(data.startTime);
            console.log("elapsedOffTime: ", elapsedOffTime);
            this.startOffTimer(elapsedOffTime);
          } else {
            console.error('Error loading last logged time: ', await response.text());
          }
        } catch (err) {
          console.error('Error loading last logged time: ', err);
        }
      },
      async autoNext(toNum) {
        console.log("autoNext");
        if (this.call) {
          console.log("this.call: ", this.call);
          if (this.currentProspectNumber == toNum && this.dialerActive != 'CONNECTED') {
            console.log("preNextCall");
            setTimeout(async () => {
              this.nextCall();
            }, 1000);
          }
        }
      },
      async nextProspect() {
        try {
          let userId = '123009';
          let dialerId = this.$route.query.id;
          const response = await axios.post('https://123avatars.com/v2/next-prospect', {
            user_id: userId,
            id: dialerId
          });
        } catch (err) {
          console.error("Error calling next prospect.", err);
        }
      },
      toggleAudio(row) {
        if (this.playingCallId === row.id) {
          this.audio.pause();
          this.audio.currentTime = 0;
          this.audio = null;
          this.playingCallId = null;
        } else {
          if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
          }
          let url = row.recordingUrl;
          if (url) {
            this.audio = new Audio(url);
            this.audio.play();
            this.playingCallId = row.id;
            this.audio.onended = () => {
              this.audio = null;
              this.playingCallId = null;
            };
          } else {
            alert("No audio URL available");
          }
        }
      },
      convertSecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} min${minutes !== 1 ? 's' : ''} ${remainingSeconds} sec${remainingSeconds !== 1 ? 's' : ''}`;
      },
      async nextCallButton() {
        this.ignore = true;
        this.nextCall();
      },
      async nextCall() {
        try {
          console.log("nextCall");
          if (this.dialerActive != 'OFF') {
            if(!this.paused) {
              if (this.call) {
                await this.call.hangup();
                this.call = null;
              }
              this.dialerActive = 'DIAL';
              let userId = '123009';
              let dialerId = this.$route.query.id;
              let response = await axios.post('https://123avatars.com/v2/next-call?id=' + this.$route.query.id,
                {
                  user_id: userId,
                  id: dialerId
                }
              );
              this.callProspect(response.data);
            }
          }
  
        } catch (err) {
          console.error("Error calling next prospect.", err);
        }
      },
      async startDialer() {
        try {
          console.log("this.dialerActive: ", this.dialerActive);
          if (this.dialerActive != 'OFF') {
            this.ignore = true;
            this.dialerActive = 'OFF';
            this.status = 'OFF';
            this.dialerText = 'Start Dialer';
            let userId = '123009';
            let dialerId = this.$route.query.id;
            let timezone = this.getTimezone();
            if (this.call) {
              await this.call.hangup();
              this.call = null;
              this.dialerActive = 'OFF';
            }
            this.dialerActive = 'OFF';
            const response = await axios.post('https://123avatars.com/v2/logout-dialer', {
              user_id: userId,
              id: dialerId,
              timezone: timezone
            });
            await this.saveLoggedTime(this.elapsedTime);
            this.startOffTimer(0);
            this.stopTimer();
            this.stopConnectedTimer();
          } else {
            await this.markLoggedInTime();
            this.dialerText = 'Stop Dialer';
            let userId = '123009';
            let dialerId = this.$route.query.id;
            let timezone = this.getTimezone();
            if (!userId) {
              throw new Error('User ID not found in localStorage');
            }
            this.dialerActive = 'DIAL';
            const response = await axios.post('https://123avatars.com/v2/login-dialer', {
              user_id: userId,
              id: dialerId,
              timezone: timezone
            });
            this.dialerActive = 'ON';
            this.startTimer();
            this.startConnectedTimer();
            console.log('Login successful:', response.data);
            this.callProspect(response.data);
          }
        } catch (error) {
          this.dialerActive = 'OFF';
          console.error('Error logging in to dialer:', error)
        }
      },
      callProspect(prospect) {
        console.log("prospect: ", prospect);
        this.dialerActive = 'ON';
        this.status = 'RINGING';
        this.prospect = prospect;
  
        if(this.company) {
          this.lastCompany = this.company;
          this.lastCompany.lastConnectedDuration = this.lastConnectedDuration;
        }
  
        this.company = {
          company_name: this.prospect.company_name,
          phone: this.prospect.phone,
          address: '-',
          city: '-',
          state: this.prospect.state,
          zipcode: '-',
          owner: '-',
          owner_cell: this.formatPhoneNumber(this.prospect.phone),
          owner_email: '-',
          timezone: this.prospect.ttimezone,
          leadId: this.prospect.lead_id,
          isHotLead: this.prospect.isHotLead ? 'Hot Lead' : 'Clicked'
        };
        this.fetchNotes(prospect);
        this.fetchMessages(prospect);
        console.log("messages: ", this.messages);
        this.makeCall(this.prospect.localDid, this.prospect.phone);
      },
      startTimer() {
        this.stopTimer();
        this.elapsedTime = 0;
        this.elapsedOffTime = 0;
        this.timer = setInterval(() => {
          this.elapsedTime++;
        }, 1000);
      },
      startOffTimer(offElapsedTime) {
        this.stopOffTimer();
        console.log("offElapsedTime: ", offElapsedTime);
        this.offElapsedTime = offElapsedTime;
        this.offTimer = setInterval(() => {
          this.offElapsedTime++;
        }, 1000);
      },
      startCurrentCallTimer() {
        this.stopCurrentCallTimer();
        this.currentCallElapsedTime = 0;
        this.currentCallTimer = setInterval(() => {
          if(this.status == 'CONNECTED') {
            this.currentCallElapsedTime++;
          }
        }, 1000);
      },
      stopCurrentCallTimer() {
        if (this.currentCallTimer) {
          this.lastConnectedDuration = this.connectedCallFormattedTime;
          clearInterval(this.currentCallTimer);
          this.currentCallTimer = null;
          this.currentCallElapsedTime = 0;
        }
      },
      startConnectedTimer() {
        this.stopConnectedTimer();
        this.connectedElapsedTime = 0;
        this.connectedTimer = setInterval(() => {
          if(this.status == 'CONNECTED') {
            this.connectedElapsedTime++;
          }
        }, 1000);
      },
      stopTimer() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
          this.elapsedTime = 0;
        }
      },
      stopOffTimer() {
        if (this.offTimer) {
          clearInterval(this.offTimer);
          this.offTimer = null;
          this.offElapsedTime = 0;
        }
      },
      stopConnectedTimer() {
        if (this.connectedTimer) {
          clearInterval(this.connectedTimer);
          this.connectedTimer = null;
          this.connectedElapsedTime = 0;
        }
      },
      formatPhoneNumber(phoneNumber) {
        phoneNumber = phoneNumber.toString();
        if (phoneNumber.length > 10) {
          phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
        }
        const areaCode = phoneNumber.slice(0, 3);
        const centralOfficeCode = phoneNumber.slice(3, 6);
        const stationCode = phoneNumber.slice(6, 10);
        const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
        return formattedPhoneNumber;
      },
      searchProspectCompany(company_name, state, phone) {
        const url = `https://www.google.com/search?q=${encodeURIComponent(this.formatPhoneNumber(phone))}`;
        window.open(url, '_blank');
      },
      openSignUpPage() {
        window.open(this.signupLink, '_blank');
      },
      openScriptPage() {
        window.open(this.scriptLink, '_blank');
      },
      openQAPage() {
        window.open(this.qaLink, '_blank');
      },
      openTrainingLockerPage() {
        window.open(this.trainingLockerLink, '_blank');
      },
      fetchLeads(id = null) {
        const params = {
          search: this.searchQuery,
          date: this.dateFilter,
          type: this.typeFilter,
          milestone: this.milestoneFilter,
          timezone: this.timezoneFilter,
          salesRep: this.salesRepFilter,
          shelfLife: this.shelfLifeFilter,
          hours: this.hoursFilter,
          startDate: this.customStartDate,
          endDate: this.customEndDate,
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currentPage,
        };
        id = this.$route.query.id;
        if (id) {
          params.id = id;
          axios.get('https://123avatars.com/v2/leads', { params })
            .then(response => {
              if (this.username == null) {
                this.sipDid = response.data.sip_did
                this.username = response.data.username;
                this.password = response.data.password;
                this.currentSalesRepId = response.data.sales_rep_id;
                this.managerId = response.data.manager_id;
                this.currentSalesRepName = response.data.sales_rep;
                this.calls = response.data.leads;
                this.missedCalls = response.data.missed_calls;
                this.voicemails = response.data.voicemails;
                //this.connect();
              }
              this.scriptLink = response.data.scriptLink;
              this.signupLink = response.data.signupLink;
              this.qaLink = response.data.qaLink;
              this.trainingLockerLink = response.data.trainingLockerLink;
            })
            .catch(error => {
              console.error('Error fetching leads:', error);
            });
        }
      },
      fetchNotes() {
        this.notes = [];
        const userId = localStorage.getItem('userId');
        axios.get(`https://123avatars.com/v2/notes`, { params: { leadId: this.prospect.lead_id, userId } })
          .then(response => {
            this.notes = response.data.notes;
          })
          .catch(error => {
            console.error('Error fetching notes:', error);
            this.notes = [];
          });
      },
      fetchStats() {
        let id = this.$route.query.id;
        if (id) {
          const params = { uuid: id };
          axios.get('https://123avatars.com/v2/stats', { params })
            .then(response => {
              this.stats.today = response.data.today;
              this.stats.thisWeek = response.data.thisWeek;
              console.log('boolean: ', this.salesNumber != response.data.today.sales);
              if (this.salesNumber != response.data.today.sales) {
                this.salesNumber = response.data.today.sales;
                this.showNotification = true;
                console.log("hideNotification1");
                setTimeout(async () => {
                  console.log("hide notification");
                  this.showNotification = false;
                }, 10000);
              }
              if(this.salesNumber == 0 && response.data.today.sales == 0) {
  
              }
              this.upcomingCallback = response.data.upcomingCallback;
            })
            .catch(error => {
              console.error('Error fetching stats:', error);
            });
        }
      },
      searchTable() {
        //this.fetchLeads();
      },
      filterTable() {
        //this.fetchLeads();
      },
      filterByMilestone(milestone) {
        this.milestoneFilter = milestone;
        // this.fetchLeads();
      },
      applyCustomDateRange() {
        // this.fetchLeads();
      },
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.currentPage = page;
          //this.fetchLeads();
        }
      },
      updateRowsPerPage() {
        this.currentPage = 1;
        // this.fetchLeads();
      },
      sortTable(property) {
        const direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
        this.leads.sort((a, b) => {
          let x = a[property];
          let y = b[property];
          if (property === 'date') {
            x = new Date(a.start_time);
            y = new Date(b.start_time);
          }
          if (direction === 'asc') {
            return x > y ? 1 : x < y ? -1 : 0;
          } else {
            return x < y ? 1 : x > y ? -1 : 0;
          }
        });
        this.sortDirection = direction;
        this.filteredRows = this._filteredRows();
      },
      matchSearchQuery(lead) {
        const query = this.searchQuery.toLowerCase();
        return (
          lead.phone.toLowerCase().includes(query) ||
          lead.company_name.toLowerCase().includes(query) ||
          lead.state.toLowerCase().includes(query) ||
          (lead.tags || []).some(tag => tag.toLowerCase().includes(query))
        );
      },
      matchDateFilter(date) {
        const d = new Date(date);
        const today = new Date();
        switch (this.dateFilter) {
          case 'All':
            return false;
          case 'Today':
            return this.isSameDay(today, d);
          case 'Yesterday':
            const yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            return this.isSameDay(yesterday, d);
          case 'This Week':
            return this.isSameWeek(today, d);
          case 'Last Week':
            const lastWeek = new Date();
            lastWeek.setDate(today.getDate() - 7);
            return this.isSameWeek(lastWeek, d);
          case 'This Month':
            return this.isSameMonth(today, d);
          case 'Last Month':
            const lastMonth = new Date();
            lastMonth.setMonth(today.getMonth() - 1);
            return this.isSameMonth(lastMonth, d);
          case 'Last 7 Days':
            const last7Days = new Date();
            last7Days.setDate(today.getDate() - 7);
            return d >= last7Days && d <= today;
          case 'Last 30 Days':
            const last30Days = new Date();
            last30Days.setDate(today.getDate() - 30);
            return d >= last30Days && d <= today;
          case 'Last 90 Days':
            const last90Days = new Date();
            last90Days.setDate(today.getDate() - 90);
            return d >= last90Days && d <= today;
          case 'YTD':
            const startOfYear = new Date(today.getFullYear(), 0, 1);
            return d >= startOfYear && d <= today;
          case 'Custom':
            const startDate = new Date(this.customStartDate);
            const endDate = new Date(this.customEndDate);
            return d >= startDate && d <= endDate;
          default:
            return true;
        }
      },
      isSameDay(date1, date2) {
        return (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        );
      },
      isSameWeek(date1, date2) {
        const startOfWeek1 = new Date(date1);
        startOfWeek1.setDate(date1.getDate() - date1.getDay());
        startOfWeek1.setHours(0, 0, 0, 0);
        const startOfWeek2 = new Date(date2);
        startOfWeek2.setDate(date2.getDate() - date2.getDay());
        startOfWeek2.setHours(0, 0, 0, 0);
        return startOfWeek1.getTime() === startOfWeek2.getTime();
      },
      isSameMonth(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
      },
      matchShelfLifeFilter(shelfLife) {
        switch (this.shelfLifeFilter) {
          case '1 Day':
            return shelfLife === 1;
          case 'Under 30 Days':
            return shelfLife < 30;
          case 'Over 30 Days':
            return shelfLife > 30;
          case 'Over 90 Days':
            return shelfLife > 90;
          case 'Over 180 Days':
            return shelfLife > 180;
          case 'Over 365 Days':
            return shelfLife > 365;
          default:
            return true;
        }
      },
      isDuringBusinessHours(timeZone) {
        const timeZoneMapping = {
          EST: 'America/New_York',
          PST: 'America/Los_Angeles',
          CST: 'America/Chicago',
          MST: 'America/Denver',
          HST: 'Pacific/Honolulu',
          AKST: 'America/Anchorage',
        };
        const ianaTimeZone = timeZoneMapping[timeZone];
        if (!ianaTimeZone) {
          throw new Error(`Unrecognized timezone abbreviation: ${timeZone}`);
        }
        const now = new Date();
        const options = {
          timeZone: ianaTimeZone,
          hour: '2-digit',
          hour12: false,
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(now);
        const hour = parseInt(parts.find(p => p.type === 'hour').value, 10);
        return hour >= 9 && hour < 17;
      },
      getLocalTime(date, timeZone) {
        const timeZoneMapping = {
          EST: 'America/New_York',
          PST: 'America/Los_Angeles',
          CST: 'America/Chicago',
          MST: 'America/Denver',
          HST: 'Pacific/Honolulu',
          AKST: 'America/Anchorage',
        };
        const ianaTimeZone = timeZoneMapping[timeZone];
        if (!ianaTimeZone) {
          return new Date(date).toLocaleTimeString();
        }
        return new Date(date).toLocaleTimeString('en-US', { timeZone: ianaTimeZone });
      },
      updateLead(row) {
        axios.put(`https://123avatars.com/v2/leads/${row.sales_lead_id}`, row)
          .then(response => {
            console.log('Lead updated:', response.data);
          })
          .catch(error => {
            console.error('Error updating lead:', error);
          });
      },
      openNotesPopup(companyName, leadId) {
        this.notesCompanyName = `Notes for ${companyName}`;
        this.currentLeadId = leadId;
        this.showOverlay = true;
        this.showNotesPopup = true;
        this.fetchNotes();
      },
      closePopup() {
        this.showOverlay = false;
        this.showNotesPopup = false;
        this.showMessagesPopup = false;
        this.currentLeadId = null;
        this.fetchNotes();
      },
      saveNote() {
        if (!this.newNoteText.trim()) return;
        const newNote = {
          userId: localStorage.getItem("userId"),
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
          text: this.newNoteText.trim(),
        };
        axios.post(`https://123avatars.com/v2/leads/${this.currentLeadId}/notes`, newNote)
          .then(response => {
            this.currentNotes.push(newNote);
            this.newNoteText = '';
            this.fetchNotes();
            //this.fetchLeads();
          })
          .catch(error => {
            console.error('Error saving note:', error);
          });
      },
      deleteNote(index, noteId) {
        axios.delete(`https://123avatars.com/v2/leads/${this.currentLeadId}/notes/${noteId}`)
          .then(response => {
            this.currentNotes.splice(index, 1);
            //this.fetchLeads();
          })
          .catch(error => {
            console.error('Error deleting note:', error);
          });
      },
      addTag(rowIndex) {
        const newTag = this.filteredRows[rowIndex].newTag.trim();
        if (newTag) {
          this.filteredRows[rowIndex].tags.push(newTag);
          this.filteredRows[rowIndex].newTag = '';
          this.updateLead(this.filteredRows[rowIndex]);
        }
      },
      removeTag(rowIndex, tagIndex) {
        this.filteredRows[rowIndex].tags.splice(tagIndex, 1);
        this.updateLead(this.filteredRows[rowIndex]);
      },
      connect() {
        console.log('init: ',  {
          login: this.username,
          password: this.password,
          callerIdName: this.callerIdName,
          callerIdNumber: this.callerIdNumber,
        });
        this.telnyxRTC = new TelnyxRTC({
          login: this.username,
          password: this.password,
          callerIdName: this.callerIdName,
          callerIdNumber: this.callerIdNumber,
        });

        console.log('telnyxRTC: ', this.telnyxRTC);

        this.telnyxRTC.on('telnyx.notification', async (notification) => {

          try {
            const call = notification.call;
            if (notification.type === 'callUpdate' && call.state === 'ringing') {
              if(call.options.remoteCallerNumber == "9492884866" || call.options.remoteCallerNumber == "3125300209" ||
                call.options.remoteCallerNumber == "+19492884866" || call.options.remoteCallerNumber == "+13125300209"
              ) {

              }
            }
          } catch (err) {
            console.log("Error answering call: ", err);
          }
          
          if(notification.call?.direction == 'outbound' && this.showDialerModal) {
            if (notification.type === 'callUpdate' && this.call.state === 'ringing') {
              this.attachStreamToAudioElement(this.call.remoteStream);
             // this.updateDialerStatus('Dialing');
            }
            if (notification.type === 'callUpdate' && this.call.state === 'early') {
              if (this.call) {
                this.attachStreamToAudioElement(this.call.remoteStream);
             //   this.updateDialerStatus('Dialing');
              }
            }
            if (notification.type === 'callUpdate' && this.call.state === 'active') {
              this.processConnectedCall();
            }
            if (notification.type === 'callUpdate' && this.call.state === 'destroy') {
              if (this.call) {
                this.call = null;
                if (this.dialerActive != 'OFF') {
                  //this.dialerActive = 'DIAL';
                }
              }
            }
            if (notification.type === 'callUpdate' && this.call?.state === 'hangup') {
              if(notification.call.direction == 'outbound') {
                let prospectNumber = '';
                if(this.prospect) {
                  prospectNumber  = '+1' + this.prospect.phone;
                } else {
                  prospectNumber = this.phoneNumber;
                  if(prospectNumber.length == 10) {
                    prospectNumber = '+1' + prospectNumber;
                  }
                }
                console.log('prospectNumber: ', prospectNumber);
                if(notification.call.options.destinationNumber == prospectNumber) {
                  this.handleCallHangup(this.call.cause);
                }
              }
            }
          }

          if(notification.call?.direction == 'outbound' && !this.showDialerModal) {
            this.call = notification.call;
            console.log("outboundNotification: ", notification);
            if (notification.type === 'callUpdate' && this.call.state === 'ringing') {
              this.attachStreamToAudioElement(this.call.remoteStream);
             // this.updateDialerStatus('Dialing');
            }
            if (notification.type === 'callUpdate' && this.call.state === 'trying') {
              this.playRingback();
              if (this.call) {
           //     this.updateDialerStatus('Dialing');
              }
            }
            if (notification.type === 'callUpdate' && this.call.state === 'early') {
              if (this.call) {
                this.attachStreamToAudioElement(this.call.remoteStream);
             //   this.updateDialerStatus('Dialing');
              }
            }
            if (notification.type === 'callUpdate' && this.call.state === 'active') {
              this.processConnectedCall();
            }
            if (notification.type === 'callUpdate' && this.call.state === 'destroy') {
              if (this.call) {
                this.call = null;
                if (this.dialerActive != 'OFF') {

                }
              }
            }
            if (notification.type === 'callUpdate' && this.call?.state === 'hangup') {
              if(notification.call.direction == 'outbound') {
                let prospectNumber = '';
                if(this.prospect) {
                  prospectNumber  = '+1' + this.prospect.phone;
                } else {
                  prospectNumber = this.phoneNumber;
                  if(prospectNumber.length == 10) {
                    prospectNumber = '+1' + prospectNumber;
                  }
                }
                console.log('prospectNumber: ', prospectNumber);
                if(notification.call.options.destinationNumber == prospectNumber) {
                  this.handleCallHangup(this.call.cause);
                }
              }
            }
          }
          if(notification.call?.direction == 'inbound') {
            console.log("inboundNotification: ", notification);

          }
        });
        this.telnyxRTC.connect();
      },
      async mergeCalls(call1, call2) {
        try {
          console.log('Merging calls:', call1, call2);
          await call1.bridge({
            call_control_id: call2.call_control_id,
            play_ringtone: false,
            park_after_unbridge: 'self',
          });
          console.log('Calls merged successfully.');
        } catch (error) {
          console.error('Error merging calls:', error);
        }
      },
      handleCallHangup(cause) {
        if (cause === 'NORMAL_CLEARING') {
          console.log('The call was ended normally.');
          console.log("nextCall");
          if (!this.ignore) {
            this.status = 'HUNG UP';
            this.dialerActive = 'DIAL';
            setTimeout(async () => {
              await this.nextCall();
            }, 7000);
          } else {
            this.ignore = false;
          }
        } else if (cause === 'USER_BUSY') {
          this.status = 'USER BUSY';
          console.log('The other side is busy.');
        } else if (cause === 'CALL_REJECTED') {
          this.status = 'CALL REJECTED';
          console.log('The call was rejected by the other side.');
        } else if (cause === 'DECLINE') {
          this.status = 'DECLINED';
        } else {
          console.log('The call was ended with cause:', cause);
        }
      },
      attachStreamToAudioElement(stream) {
        const audioElement = document.getElementById('telnyx-audio');
        audioElement.srcObject = stream;
        try {
        // try {
        //   this.outboundStream = stream.clone();
          
        // this.outboundStream.getTracks().forEach(async (track) => {
        //   await this.listeningCall.peer.instance.addTrack(track);
        //   debugger;
        //   console.log("peerStream12345: ", this.listeningCall.peer.instance);
        // });
        // console.log("peerStream12345: ", this.listeningCall.peer.instance);
        } catch (err) {
          console.error("error merging streams: ", err);
        }
      },
      dialPadPress(key) {
        this.callDestination += key;
      },
      makeCall(fromNum, toNum) {
        if (this.telnyxRTC) {
          console.log("toNum: ", toNum);
          if (toNum.length == 10) {
            toNum = '+1' + toNum;
          }
          this.call = this.telnyxRTC.newCall({
            destinationNumber: toNum,
            callerNumber: fromNum,
            webhook_url: `https://123avatars.com/v2/telnyx-callback-dialer`,
            record: "record-from-answer",
            record_format: "mp3",
            record_channels: "single",
            park_after_unbridge: 'self'
          });
          
          this.startCurrentCallTimer();
  
          this.currentProspectNumber = toNum;
        }
      },
      async loadManager(managerId) {
        try {
          this.role = await AuthService.getUserRole();
          this.managerId = await AuthService.getCognitoSub();
          console.log('managerId: ', this.managerId);
          // Make the GET request to fetch the manager data
          const response = await axios.get(`https://123avatars.com/v2/manager?id=${this.managerId}`);

          // Check if the response contains data
          if (response && response.data) {
            this.manager = response.data; // Set manager data to component's state

            this.username = response.data.sip_username;
            this.password = response.data.sip_password;
            this.callerIdName = response.data.sip_did;
            this.callerIdNumber = response.data.sip_did;
            this.connect();
          } else {
            this.error = 'No data found for the given manager ID';
          }
        } catch (err) {
          // Handle any errors
          console.error('Error loading manager:', err);
          this.error = 'An error occurred while fetching manager details';
        }
      }
    },
    mounted() {
      this.loadManager()
      this.loadLastLoggedTime();
      this.loadExternalScript();
      this.loadTypeformScript();
      this.fetchObjections();
     // this.loadEmbeddedExternalScript();
     // this.loadTypeformScript();
      this.startScheduleCheck();
      this.startHeartbeat();
    }
  };
  </script>
  
  <style scoped>
  body {
    background-color: #F4F8FF;
  }
  
  header {
    background-color: #579aff;
    padding: 20px;
    color: #fff;
  }
  header h1 {
    margin-bottom: 0;
    color: #fff;
    font-weight: bold;
  }
  header .dialer {
    margin: 0 30px;
  }
  header .dialer .btn {
    color: #fff;
    border: 0px solid #fff;
    font-weight: bold;
  }
  header .dialer .btn:hover {
    color: #59f;
    background-color: #fff;
  }
  header .dialer .dot-outer {
    margin: 0 15px;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
  }
  header .dialer .dot-inner {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin: 5px;
  }
  header .dialer .dot-inner.off {
    background-color: #FF0000;
  }
  header .dialer .dot-inner.on {
    background-color: #00FF0A;
  }
  header .dialer .dot-inner.dialing {
    background-color: #FFF500;
  }
  header .buttons .btn {
    padding: 10px;
    position: relative;
    color: #fff;
    border: 0px solid #fff;
    height: 40px;
    margin-left: 20px;
  }
  header .buttons .btn svg {
    fill: #fff;
  }
  header .buttons .btn:hover {
    background-color: #fff;
  }
  header .buttons .btn:hover svg {
    fill: #59f;
  }
  header .buttons .btn .badge {
    position: absolute;
    top: -13px;
    right: -15px;
    background-color: #ff0000;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    vertical-align: sub;
    font-size: 0.9em;
    text-align: center;
    padding: 6px 0;
  }
  
  main .sidebar {
    padding: 0;
    color: #fff;
    background-color: #364C6C;
    font-weight: bold;
  }
  main .sidebar .side-nav {
    display: block;
    padding: 15px 20px;
    color: #fff;
    text-decoration: none;
  }
  main .sidebar .side-nav:hover {
    background-color: #263750;
  }
  main .sidebar .side-nav.active {
    background-color: #627CA3;
  }
  main .statssales {
    background-color: #364C6C;
    border-radius: 40px 0 0 40px;
    padding: 15px 15px 1px 15px;
    margin-top: 10px;
    line-height: 1.3rem;
  }
  main .statssales .inner-box {
    margin-bottom: 30px;
  }
  main .content {
    padding: 10px;
  }
  main .content .btn {
    background-color: #579AFF;
    color: #fff;
    margin: 10px;
    border: 2px solid #009aff;
  }
  main .content .btn:hover {
    background-color: #fff;
    color: #579AFF;
    border-color: #579AFF;
    margin: 10px;
  }
  main .rounded-container {
    margin: 10px;
    background-color: #fff;
    border-radius: 20px;
    background-clip: border-box;
  }
  main .rounded-container .btn,
  main .rounded-container .btn:hover {
    margin: 0;
  }
  main .rounded-container .bold {
    font-weight: bold;
  }
  main .rounded-container h2 {
    background-color: #C5DBFF;
    padding: 20px;
    font-size: 1.5rem;
    border-radius: 20px 20px 0 0;
  }
  main .rounded-container h3 {
    background-color: #C5DBFF;
    padding: 20px;
    font-size: 1.5rem;
    border-radius: 20px 20px 0 0;
    font-weight: bold;
  }
  main .rounded-container .container-body {
    padding: 10px 20px 20px;
  }
  main footer {
    padding: 10px;
  }
  
  .notification-content {
    text-align: center;
  }
  
  .notification {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  
  #dialer_table {
    width: 100%;
    font-size: 1.5em;
  }
  
  #dialer_table tr td {
    text-align: center;
    height: 50px;
    width: 33%;
  }
  
  #dialer_table #dialer_input_td {
    border-bottom: 1px solid #fafafa;
  }
  
  #dialer_table #dialer_input_td input {
    width: 100%;
    border: none;
    font-size: 1.6em;
  }
  
  /* Remove arrows from type number input : Chrome, Safari, Edge, Opera */
  #dialer_table #dialer_input_td input::-webkit-outer-spin-button,
  #dialer_table #dialer_input_td input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Remove arrows from type number input : Firefox */
  #dialer_table #dialer_input_td input[type=number] {
    -moz-appearance: textfield;
  }
  
  #dialer_table #dialer_input_td input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc;
    opacity: 1; /* Firefox */
  }
  
  #dialer_table #dialer_input_td input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cccccc;
  }
  
  #dialer_table #dialer_input_td input::-ms-input-placeholder { /* Microsoft Edge */
    color: #cccccc;
  }
  
  #dialer_table #dialer_call_btn_td {
    color: #ffffff;
    background-color: #579aff;
    border: solid 2px #579aff;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    padding: 5px 32px;
    text-align: center;
    display: inline-block;
    margin: 10px 2px 4px 2px;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    --webkit-transition: all 300ms ease;
  }
  
  #dialer_table #dialer_call_btn_td:hover {
    background-color: #ffffff;
    color: #579aff;
  }
  
  #dialer_table .dialer_num_tr td {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
  
  #dialer_table .dialer_num_tr td:nth-child(1) {
    border-right: 1px solid #fafafa;
  }
  
  #dialer_table .dialer_num_tr td:nth-child(3) {
    border-left: 1px solid #fafafa;
  }
  
  #dialer_table .dialer_num_tr:nth-child(1) td,
  #dialer_table .dialer_num_tr:nth-child(2) td,
  #dialer_table .dialer_num_tr:nth-child(3) td,
  #dialer_table .dialer_num_tr:nth-child(4) td {
    border-bottom: 1px solid #fafafa;
  }
  
  #dialer_table .dialer_num_tr .dialer_num {
    color: #000000;
    cursor: pointer;
  }
  
  #dialer_table .dialer_num_tr .dialer_num:hover {
    background-color: #579aff;
    color: #fff;
  }
  
  #dialer_table .dialer_num_tr:nth-child(0) td {
    border-top: 1px solid #fafafa;
  }
  
  #dialer_table .dialer_del_td img {
    cursor: pointer;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050; /* Bootstrap default modal z-index */
  }
  
  .modal-centered {
    position: relative;
  }
  
  .conversation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    max-height: 500px;
    overflow-y: auto;
  }
  .bubble-row {
    display: flex;
    align-items: flex-end;
  }
  .bubble-row.right {
    justify-content: flex-end;
  }
  .bubble-row.left {
    justify-content: flex-start;
  }
  .bubble-container {
    max-width: 60%;
    padding: 10px;
    border-radius: 20px;
    position: relative;
  }
  .bubble-container .bubble {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #579AFF;
    color: white;
  }
  .bubble-container .bubble svg.tail {
    position: absolute;
    bottom: -5px;
  }
  .bubble-container .bubble svg.tail path {
    fill: white;
  }
  .bubble-container .subtitle {
    margin-top: 5px;
    font-size: 0.8em;
  }
  .bubble-container .subtitle.bold {
    font-weight: bold;
  }
  .bubble-container.right .bubble {
    background-color: #F0F0F0;
    color: black;
  }
  .bubble-container.right .bubble svg.tail path {
    fill: #F0F0F0;
  }
  .bubble-container.left .bubble {
    background-color: #579AFF;
    color: white;
  }
  .bubble-container.left .bubble svg.tail path {
    fill: #579AFF;
  }
  
  .active-div {
    /* Styles for active div */
    background-color: green;
  }
  
  .paused-div {
    /* Styles for paused div */
    opacity: 0.5;
  }
  
  .google-icon {
    display: inline-block;
    margin-right: 0.5rem;
  }
  
  
  .inner-box {
    background-color: #2d3748;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
    color: #ffffff;
  }
  
  .rounded-container {
    background-color: #e2e8f0;
    border-radius: 10px;
    padding: 10px;
  }
  
  h3 {
    background-color: #bee3f8;
    padding: 10px;
    border-radius: 10px;
    color: #2d3748;
    text-align: center;
  }
  
  .container-body {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }
  
  .accordion {
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    background-color: #e2e8f0;
    margin-bottom: 5px;
  }
  
  .active,
  .accordion:hover {
    background-color: #ccc;
  }
  
  .panel {
    padding: 10px;
    background-color: white;
    display: none;
    overflow: hidden;
    font-size: 0.9em;
    color: #2d3748;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    margin-bottom: 10px;
  }
  
  /*# sourceMappingURL=style.css.map */
  </style>
