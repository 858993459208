<template>
  <div>
    <div class="flex items-center gap-2" style="padding-left: 10px;">
      <span class="font-bold text-1xl">Website:</span>
      <div @click="toggleFilterWebsite()" class="cursor-pointer">
        <checkSVG v-if="websiteBoth === true ? true : websiteYes" :size="24" class="checkbox-checked"
          :fill="roleColor()" :stroke="roleColor()" />
        <unCheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
      </div>
      <label for="toFilter" class="font-bold" style="margin-top: 8px;">Yes</label>
      <div @click="toggleFilterWebsite()" class="cursor-pointer">
        <checkSVG v-if="websiteBoth === true ? true : !websiteYes" :size="24" class="checkbox-checked"
          :fill="roleColor()" :stroke="roleColor()" />
        <unCheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
      </div>
      <label for="toFilter" class="font-bold" style="margin-top: 8px;">No</label>
      <div @click="toggleFilterBoth()" class="cursor-pointer">
        <checkSVG v-if="websiteBoth" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
        <unCheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
      </div>
      <label for="toFilter" class="font-bold" style="margin-top: 8px;">Both</label>
    </div>
    <div>

    </div>
  </div>
</template>

<script>
import checkSVG from '../SVG-icons/checkedSVG.vue';
import unCheckedSVG from '../SVG-icons/unCheckedSVG.vue';
import AuthService from '../../_services/AuthService';

export default {
  components: {
    checkSVG,
    unCheckedSVG
  },
  data() {
    return {
      websiteYes: true,
      websiteBoth: false,
      role: '',
      userId: '',
    }
  },
  methods: {
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    toggleFilterWebsite() {
      this.websiteYes = !this.websiteYes;
    },
    toggleFilterBoth() {
      this.websiteBoth = !this.websiteBoth;
    },
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
    }
  },
  mounted() {
    this.initUsers();
  }
}
</script>

<style scoped>
.checkbox-checked,
.checkbox-unchecked {
  cursor: pointer;
}
</style>