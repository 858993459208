import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase";
import Userauth from "../auth/js/index";

import Body from "../components/body";
import Auth from "../auth/auth";

/* Auth */
import Login from "../components/auth/Login";

/* maintenance */
import Maintenance from "../pages/maintenance";

/* Error Pages */
import Error400 from "../errors/error_400";
import Error401 from "../errors/error_401";
import Error403 from "../errors/error_403";
import Error404 from "../errors/error_404";
import Error500 from "../errors/error_500";
import Error503 from "../errors/error_503";

/* Dashboards */
import Dashboard from "../pages/dashboard/dashboard";
import Default from "../pages/dashboard/dashboard";
/* File Manager  */
import FileManager from "../pages/filemanager";

/* Authentication */
// import LoginOne from "../pages/authentication/login_one";
import LoginTwo from "../pages/authentication/login_two";
import ForgetPassword from "../pages/authentication/forget_password";
// import ResetPassword from "../pages/authentication/reset_password";

import integrations from "../pages/integrations/integrations";
import wakeups from "../pages/wakeups";
import domains from "../pages/domains";
import phones from "../pages/phones";
import templates from "../pages/templates";
import backOffice from "../pages/backOffice";
import hooks from "../pages/hooks";
import reports from "../pages/reports";
import persona from "../pages/persona";
import schedules from "../pages/schedules";
import ivr from "../pages/ivr";
import webchat from "../pages/webchat";
import SalesDashboard from "../pages/sales/SalesDashboard";
import SalesManagement from "../pages/sales/SalesManagement";
import LeadSummary from "../pages/sales/LeadSummary";
import AccessDenied from "../pages/AccessDenied";
import ScoreBoard from "../pages/scoreboard";
import SalesTeamManagement from "../pages/sales/SalesTeamManagement";
import CallReport from "../components/reports/callReports";
import CallbacksReport from "../components/reports/callbacksReport";
import DailyReport from "../components/reports/dailyReports";
import SalesDashboardAdmin from "../pages/sales/SalesDashboardAdmin";
import phone from "../pages/sales/phone";
import SoftPhone from "../pages/sales/SoftPhone";
import PersonalDashboard from "../pages/sales/PersonalDashboard";
import AutomaticSales from "../components/sales/AutomaticSales";
import CallReports from "../pages/sales/CallReports";
import CallerIDs from "../pages/CallerIds";
import Objections from "../pages/Objections";
import ManagerDashboard from "../pages/sales/ManagerDashboard";
import Temp from "../pages/sales/Temp";
import Template from "../pages/templates";
import Users from "../pages/sales/Users";
import SpifWidget from "../pages/sales/SpifWidget";
import Panel from "../pages/panel.vue";
import Tags from "../components/Tags";
import Blacklist from "../components/Blacklist";
import Schedules from "../components/Schedules";
import Broadcast from "../components/Broadcast.vue";
import Inbox from "../components/inbox/Inbox.vue";

// component

Vue.use(Router);

const routes = [
  { path: "", redirect: { name: "dashboard" } },
  {
    path: "/template",
    component: Template,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/sales",
    component: Temp,
    meta: {
      requiresAuth: false
    }
  },
  { path: "/dashboard",
    name: "sales",
    title: "Sales | MyLeadFarm ",
    component: PersonalDashboard,
    meta: {
      requiresAuth: true
    }
  },
  { path: "/temp",
    component: CallReports,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/personal-dashboard",
    name: "Personal Dashboard",
    component: PersonalDashboard,
    meta: {
      title: "Personal Dashboard | 123Avatars",
    },
  },
  { path: "/manager-dashboard",
    name: 'manager-dashboard',
    component: ManagerDashboard,
    meta: {
      title: "Manager Dashboard | 123Avatars",
      requiresAuth: true
    }
  },
  { path: "/access-denied",
    component: AccessDenied,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/",
    component: Body,
    meta: {
      requiresAuth: true,
      role: 'Admin'
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          title: "Dashboard | 123Avatars",
        },
      },
      {
        path: "integrations",
        name: "integrations",
        component: integrations,
        meta: {
          title: "integrations | 123Avatars",
        },
      },
      {
        path: "knowledge-center",
        name: "Knowledge Center",
        component: FileManager,
        meta: {
          title: "Knowledge Center | 123Avatars",
        },
      },
      {
        path: "phone",
        name: "Phone",
        component: phone,
        meta: {
          title: "Phone | 123Avatars",
        },
      },
      {
        path: "soft-phone",
        name: "Soft Phone",
        component: SoftPhone,
        meta: {
          title: "Soft Phone | 123Avatars",
        },
      },
      {
        path: "templates",
        name: "templates",
        component: templates,
        meta: {
          title: "Bulk Templates | 123Avatars",
        },
      },
      {
        path: "wakeups",
        name: "wakeups",
        component: wakeups,
        meta: {
          title: "Wakeup Funnels | 123Avatars",
        },
      },
      {
        path: "hooks",
        name: "hooks",
        component: hooks,
        meta: {
          title: "Smart Hooks | 123Avatars",
        },
      },
      {
        path: "reports",
        name: "reports",
        component: reports,
        meta: {
          title: "Reports | 123Avatars",
        },
      },
      {
        path: "avatar-persona",
        name: "Avatar Persona",
        component: persona,
        meta: {
          title: "Avatar Persona | 123Avatars",
        },
      },
      {
        path: "ivr",
        name: "IVR",
        component: ivr,
        meta: {
          title: "IVR | 123Avatars",
        },
      },
      {
        path: "schedules",
        name: "Schedules",
        component: schedules,
        meta: {
          title: "Schedules | 123Avatars",
        },
      },
      {
        path: "webchat",
        name: "Web Chat",
        component: webchat,
        meta: {
          title: "Web Chat | 123Avatars",
        },
      },
      { path: "/sales-management",
        component: SalesManagement,
        meta: {
          title: "Sales Management | 123Avatars",
        }
      },
      { path: "/sales-dashboard-admin",
        component: SalesDashboardAdmin,
        meta: {
          title: "Sales Dashboard Admin | 123Avatars",
        }
      },
      { path: "/lead-summary",
        component: LeadSummary,
        meta: {
          title: "Lead Summary | 123Avatars",
        }
      },
      { path: "/automatic-sales",
        component: AutomaticSales,
        meta: {
          title: "Automatic Sales | 123Avatars",
        }
      },
      { path: "/caller-ids",
        component: CallerIDs,
        meta: {
          title: "Caller IDs | 123Avatars",
        }
      },
      { path: "/users",
        component: Users,
        meta: {
          title: "Users | 123Avatars",
        }
      },
      { path: "/spiffs",
        component: SpifWidget,
        meta: {
          title: "Spifs | 123Avatars",
        }
      },
      { path: "/objections",
        component: Objections,
        meta: {
          title: "Objections | 123Avatars",
        }
      },
      {
        path: "domains",
        name: "domains",
        component: domains,
        meta: {
          title: "Domains | 123Avatars",
        },
      },
      {
        path: "phones",
        name: "phones",
        component: phones,
        meta: {
          title: "Phones | 123Avatars",
        },
      },
      {
        path: "panel",
        name: "Panel",
        component: Panel,
        meta: {
          title: "Panel | 123Avatars",
        },
      },
      {
        path: "scoreboard",
        name: "Scoreboard",
        component: ScoreBoard,
        meta: {
          title: "Scoreboard Report | 123Avatars",
        },
      },
      {
        path: "call-report",
        name: "Call Reports",
        component: CallReport,
        meta: {
          title: "Calls Report | 123Avatars",
        },
      },
      {
        path: "callbacks-report",
        name: "Callbacks Reports",
        component: CallbacksReport,
        meta: {
          title: "Callbacks Report | 123Avatars",
        },
      },
      {
        path: "daily-report",
        name: "Daily Report",
        component: DailyReport,
        meta: {
          title: "Daily Report | 123Avatars",
        },
      },
      {
        path: "office/:id/:campId",
        name: "office",
        component: backOffice,
        meta: {
          title: "BackOffice | 123Avatars",
        },
      },
      {
        path: "default",
        name: "default",
        component: Default,
        meta: {
          title: "Default Dashboard | 123Avatars",
        },
      },
      { path: "tags",
        name: "tags",
        title: "Tags | MyLeadFarm ",
        component: Tags,
        meta: {
          requiresAuth: true
        }
      },
      { path: "blacklist",
        name: "blacklist",
        title: "Blacklist | MyLeadFarm ",
        component: Blacklist,
        meta: {
          requiresAuth: true
        }
      },
      { path: "schedules",
        name: "schedules",
        title: "Schedules | MyLeadFarm ",
        component: Schedules,
        meta: {
          requiresAuth: true
        }
      },
      ,
      { path: "broadcast",
        name: "broadcast",
        title: "Broadcast | MyLeadFarm ",
        component: Broadcast,
        meta: {
          requiresAuth: true
        }
      },
      { path: "inbox",
        name: "inbox",
        title: "Inbox | MyLeadFarm ",
        component: Inbox,
        meta: {
          requiresAuth: true
        }
      },
    ],
  },
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "login",
        name: "Login 1",
        component: Login,
        meta: {
          title: " login | 123Avatars",
        },
      },
    ],
  },
  {
    path: "/error-400",
    name: "Error400",
    component: Error400,
    meta: {
      title: "Error400 | 123Avatars",
    },
  },
  {
    path: "/error-401",
    name: "Error401",
    component: Error401,
    meta: {
      title: "Error401 | 123Avatars",
    },
  },
  {
    path: "/error-403",
    name: "Error403",
    component: Error403,
    meta: {
      title: "Error403 | 123Avatars",
    },
  },
  {
    path: "/error-404",
    name: "Error404",
    component: Error404,
    meta: {
      title: "Error404 | 123Avatars",
    },
  },
  {
    path: "/error-500",
    name: "Error500",
    component: Error500,
    meta: {
      title: "Error500 | 123Avatars",
    },
  },
  {
    path: "/error-503",
    name: "Error503",
    component: Error503,
    meta: {
      title: "Error503 | 123Avatars",
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    meta: {
      title: "Maintenance | 123Avatars",
    },
  },
  {
    path: "/authentication/forgetpassword",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      title: "ForgetPassword | 123Avatars",
    },
  },
];

const router = new Router({
  routes,
  base: process.env.BASE_URL,
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Set the document title if specified in the route's meta
  if (to.meta.title) document.title = to.meta.title;

  // Store the last visited route in localStorage
  if (to.name) {
    localStorage.setItem("lastVisitedRoute", to.fullPath);
  }

  // Get the current user from local storage
  const user = JSON.parse(localStorage.getItem("user"));

  // Paths that do not require authentication
  const publicPaths = ["/auth/login", "/auth/register"];

  // If the route doesn't require authentication, proceed
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    return next();
  }

  // If the user is authenticated, check for role
  if (user) {
    console.log("user.role: ", user.role);

    // If the route requires a specific role, check if the user has the required role
    if (to.meta.role && user.role !== to.meta.role) {
      return next("/auth/login"); // Redirect to login if role does not match
    }

    return next(); // Proceed if role matches or no specific role is required
  }

  // If the user is not authenticated, redirect to login
  next("/auth/login");
});

// Redirect to the last visited route on refresh if no specific route is provided
const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
if (lastVisitedRoute && window.location.pathname === "/") {
  router.replace(lastVisitedRoute).catch(() => {
    router.replace("/auth/login"); // Fallback to login if the stored route is invalid
  });
}

// Utility function to check if the user is authenticated and the JWT is valid
function isAuthenticated(user) {
  if (!user || !user.token) return false; // No user or token found

  try {
    // Decode the JWT to check its expiration time
    const payload = JSON.parse(atob(user.token.split('.')[1]));
    return payload.exp > Date.now() / 1000; // Check if the token is still valid
  } catch (error) {
    console.error("Error decoding token:", error);
    return false; // Assume not authenticated if there's an error
  }
}

export default router;
